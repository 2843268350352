import React from "react";
import SaleApartment from "../../assets/icon/SaleApartment.png";
import Footer from "../../components/Footer";

import { ReactComponent as TelegramIcon } from "../../assets/icon/TelegramIcon.svg";
import { ReactComponent as EmailIcon } from "../../assets/icon/EmailIcon.svg";
import { ReactComponent as ViberIcon } from "../../assets/icon/ViberIcon.svg";
import { ReactComponent as WatsUpIcon } from "../../assets/icon/WatsUpIcon.svg";
import { ReactComponent as PhoneIcon } from "../../assets/icon/PhoneIcon.svg";

import { SaleContainer, ImageContainer, SaleWrapper } from "./styled";
import { useTranslation } from "react-i18next";

const Sale = () => {
  const { t } = useTranslation();
  return (
    <SaleWrapper>
      <SaleContainer>
        <ImageContainer src={SaleApartment}></ImageContainer>
        <div className="text">
          {t("long_term_rent.contact_specialists_perfect_property")}
        </div>
        <div className="phonNumbersContainer">
          <div className="phonNumbers">
            <PhoneIcon />
            +374 98 752072
          </div>
          <div className="phonNumbers">
            <PhoneIcon />
            +374 41 444 282
          </div>
          <div className="phonNumbers">
            <PhoneIcon />
            +374 55 012 102
          </div>
        </div>
        <div className="messengersIcon">
          <a
            href="https://api.whatsapp.com/send/?phone=+37441444282"
            data-action="share/whatsapp/share"
            target="_blank"
            rel="noreferrer"
          >
            <WatsUpIcon />
          </a>
          <a
            href="viber://chat?number=%2B37441444282"
            target="_blank"
            rel="noreferrer"
          >
            <ViberIcon />
          </a>
          <a
            href="https://telegram.me/+37441444282"
            target="_blank"
            rel="noreferrer"
          >
            <TelegramIcon />
          </a>
          <a href="mailto:daily@mystreet.am">
            <EmailIcon />
          </a>
        </div>
      </SaleContainer>
      <div className="footerContainer">
        <Footer />
      </div>
    </SaleWrapper>
  );
};

export default Sale;
