import React from "react";
import { ContactInfoContainer } from "../styled";
import { Form, Input } from "antd";
import { codeRegexp, emailRegexp } from "../../../utils/constants";
import { useTranslation } from "react-i18next";

const Contacts = ({ createCustomerInfo, onInputChange, setCreateCustomerInfo }) => {
  const { t } = useTranslation();

  const handleInputGroupChange = (event, name, value) => {
    if (codeRegexp.test(event.target.value)) {
      setCreateCustomerInfo({
        ...createCustomerInfo,
        [name]: { ...createCustomerInfo[name], [value]: event.target.value },
      });
    }
  };

  const onInputEmailChange = (event) => {
    setCreateCustomerInfo({
      ...createCustomerInfo,
      email: event.target.value,
    });
  };

  return (
    <>
      <div className="contactInfo">{t("booking.contacts")}</div>
      <ContactInfoContainer>
        <div className="left">
          <div className="text">* {t("booking.your_name")}</div>
          <Form.Item
            name="name"
            rules={[
              {
                required: true,
                min: 1,
                max: 50,
                message: t("booking.enter_valid_name"),
              },
            ]}
          >
            <Input
              placeholder={t("booking.your_name")}
              value={createCustomerInfo?.name}
              onChange={(event) => onInputChange(event, "name")}
            />
          </Form.Item>
        </div>
        <div className="right">
          <div className="text">* {t("booking.phone_number")} </div>
          <Input.Group compact className="inputGroup">
            <Form.Item
              name="code"
              rules={[
                {
                  required: true,
                  pattern: new RegExp(/^[\s()+-]*([0-9][\s()+-]*){1,10}$/),
                  message: t("booking.enter_valid_phone_number_code"),
                },
              ]}
              className="code"
            >
              <Input
                placeholder={t("booking.code")}
                defaultValue={createCustomerInfo?.tel?.code}
                onChange={(event) =>
                  handleInputGroupChange(event, "tel", "code")
                }
                style={{ width: "100%" }}
              />
            </Form.Item>
            <Form.Item
              name="phone"
              rules={[
                {
                  required: true,
                  pattern: new RegExp(/^[\s()]*([0-9][\s()+-]*){5,20}$/),
                  message: t("booking.enter_valid_phone_number"),
                },
              ]}
              className="number"
            >
              <Input
                placeholder={t("booking.phone_number")}
                defaultValue={createCustomerInfo?.tel?.number}
                onChange={(event) =>
                  handleInputGroupChange(event, "tel", "number")
                }
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Input.Group>
        </div>
      </ContactInfoContainer>
      <ContactInfoContainer>
        <div className="left">
          <div className="text">{t("booking.whatsapp")}</div>
          <Input.Group compact>
            <Input
              style={{ width: "20%" }}
              placeholder={t("booking.code")}
              defaultValue={createCustomerInfo?.whatsapp?.code}
              onChange={(event) =>
                handleInputGroupChange(event, "whatsapp", "code")
              }
            />
            <Input
              style={{ width: "80%" }}
              placeholder={t("booking.phone_number")}
              defaultValue={createCustomerInfo?.whatsapp?.number}
              onChange={(event) =>
                handleInputGroupChange(event, "whatsapp", "number")
              }
            />
          </Input.Group>
        </div>
        <div className="right">
          <div className="text">{t("booking.viber")} </div>
          <Input.Group compact>
            <Input
              style={{ width: "20%" }}
              placeholder={t("booking.code")}
              defaultValue={createCustomerInfo?.viber?.code}
              onChange={(event) =>
                handleInputGroupChange(event, "viber", "code")
              }
            />
            <Input
              style={{ width: "80%" }}
              placeholder={t("booking.phone_number")}
              defaultValue={createCustomerInfo?.viber?.number}
              onChange={(event) =>
                handleInputGroupChange(event, "viber", "number")
              }
            />
          </Input.Group>
        </div>
      </ContactInfoContainer>
      <ContactInfoContainer>
        <div className="left">
          <div className="text">{t("booking.your_telegram")}</div>
          <Input.Group compact>
            <Input
              style={{ width: "20%" }}
              placeholder={t("booking.code")}
              defaultValue={createCustomerInfo?.telegram?.code}
              onChange={(event) =>
                handleInputGroupChange(event, "telegram", "code")
              }
            />
            <Input
              style={{ width: "80%" }}
              placeholder={t("booking.phone_number")}
              defaultValue={createCustomerInfo?.telegram?.number}
              onChange={(event) =>
                handleInputGroupChange(event, "telegram", "number")
              }
            />
          </Input.Group>
        </div>
        <div className="right">
          <div className="text">* {t("booking.your_e-mail")}</div>
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                pattern: new RegExp(emailRegexp),
                message: t("booking.enter_valid_email"),
              },
            ]}
          >
            <Input
              placeholder={t("booking.your_e-mail")}
              defaultValue={createCustomerInfo?.email}
              onChange={onInputEmailChange}
            />
          </Form.Item>
        </div>
      </ContactInfoContainer>
    </>
  );
};

export default Contacts;
