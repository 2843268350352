import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Header from "./components/Header";
import DailyRent from "./pages/DailyRent";
import LongTermRent from "./pages/LongTermRent";
import Sale from "./pages/Sale";
import Booking from "./components/Booking";
import Error from "./components/Error";
import GlobalStyle from "./assets/style/styled.js";

import "./App.css";
import BookingSuccessful from "./components/BookingSuccessful";

const App = () => {
  return (
    <div>
      <GlobalStyle />
      <Header />
      <Routes>
        <Route path="/" element={<DailyRent />} />
        <Route path="/long-term-rent" element={<LongTermRent />} />
        <Route path="/sale" element={<Sale />} />
        <Route path="/booking-apartment/:id" element={<Booking />} />
        <Route path="no-access" element={<Error />} />
        <Route path="/apartment-detail/:id" element={<DailyRent />} />
        <Route path="/booking-successful" element={<BookingSuccessful />} />
        <Route path="*" element={<Navigate to="no-access" replace />} />
      </Routes>
    </div>
  );
};

export default App;
