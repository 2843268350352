import React, { useState, useEffect } from "react";
import ApartmentList from "../ApartmentList";
import ApartmentCard from "../ApartmentCard";
import { useQuery } from "@apollo/client";

import {
  GET_SINGLE_APARTAMENT_INFO,
  GET_ALL_APARTAMENT_INFO,
} from "../Graphql/query";

import { ViewApartmentDetailsContainer } from "./styled";
import { useParams } from "react-router-dom";

const ViewApartmentDetails = ({
  width,
  totalItems,
  setViewBooking,
  personsCount,
  dataState,
  filteredListData,
  filteredLoadingData,
  apartmentCount,
  setApartmentCount,
  current,
  setCurrent,
  state,
}) => {
  const [apartmentListData, setApartmentListData] = useState([]);
  const { id } = useParams();

  const { data } = useQuery(GET_SINGLE_APARTAMENT_INFO, {
    variables: { house_id: id },
  });

  const { data: listData, loading: loadingData } = useQuery(
    GET_ALL_APARTAMENT_INFO,
    {
      variables: {
        status: ["active", "booked"],
        page: current,
        size: apartmentCount,
      },
    }
  );

  useEffect(() => {
    setApartmentListData(listData);
  }, [listData, loadingData]);

  useEffect(() => {
    if (!filteredLoadingData && filteredListData) {
      setApartmentListData(filteredListData);
    }
  }, [filteredListData, filteredLoadingData]);

  useEffect(() => {
    setApartmentCount(width > 1024 ? 4 : width <= 1024 && width > 768 ? 2 : 1);
  }, [width]);

  return (
    <ViewApartmentDetailsContainer>
      <>
        <ApartmentCard
          data={data}
          setViewBooking={setViewBooking}
          personsCount={personsCount}
          dataState={dataState}
          state={state}
        />
        <ApartmentList
          setCurrent={setCurrent}
          current={current}
          houses={apartmentListData}
          apartmentCount={apartmentCount}
          totalItems={totalItems?.getAllHomesInfo?.totalItems}
          loadingData={loadingData}
        />
      </>
    </ViewApartmentDetailsContainer>
  );
};

export default ViewApartmentDetails;
