import React, { useState } from "react";
import { Image, Popover } from "antd";
import moment from "moment";
import { formatTime } from "../../utils/constants";

import { ReactComponent as BedroomIcon } from "../../assets/icon/BedroomIcon.svg";
import { ReactComponent as MaxPersonIcon } from "../../assets/icon/MaxPersonIcon.svg";
import { ReactComponent as AreaIcon } from "../../assets/icon/AreaIcon.svg";
import { ReactComponent as ElevatorIcon } from "../../assets/icon/ElevatorIcon.svg";
import { ReactComponent as BedIcon } from "../../assets/icon/BedIcon.svg";
import { ReactComponent as WifiIcon } from "../../assets/icon/WifiIcon.svg";
import { ReactComponent as IronIcon } from "../../assets/icon/IronIcon.svg";
import { ReactComponent as SwimmingPoolIcon } from "../../assets/icon/SwimmingPoolIcon.svg";
import { ReactComponent as SelfCheckIn } from "../../assets/icon/SelfCheckIn.svg";
import { ReactComponent as SlipIcon } from "../../assets/icon/SlipIcon.svg";
import { ReactComponent as NoSmokingIcon } from "../../assets/icon/NoSmokingIcon.svg";
import { ReactComponent as NoChildrenIcon } from "../../assets/icon/NoChildrenIcon.svg";
import { ReactComponent as NoPartiesIcon } from "../../assets/icon/NoPartiesIcon.svg";
import { ReactComponent as NoPetsIcon } from "../../assets/icon/NoPetsIcon.svg";
import { ReactComponent as LeftIcon } from "../../assets/icon/LeftIcon.svg";

import {
  LeftContainer,
  TitleContainer,
  ImagesContainer,
  RightImgContainer,
  InfoContainer,
  HomeRolesContainer,
  AllServicesButton,
  ServicesTextContainer,
  Row1,
  Row2,
  Row3,
} from "./styled";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const ApartmentCardLeft = ({ apartmentData }) => {
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);
  const { t } = useTranslation();
  const onDeleteServiced = () => {
    return apartmentData?.propertyes[0].services?.map((services) => {
      switch (services) {
        case "Bad":
          return <BedIcon key={services} />;
        case "Wi-Fi":
          return <WifiIcon key={services} />;
        case "Iron":
          return <IronIcon key={services} />;
        case "Washing machine":
          return <SwimmingPoolIcon key={services} />;
        default:
          break;
      }
    });
  };

  const filteredArr = () => {
    return apartmentData?.propertyes[0].services?.filter(
      (item) =>
        item !== "Bad" &&
        item !== "Wi-Fi" &&
        item !== "Iron" &&
        item !== "Washing machine"
    );
  };

  const onImagePrevue = () => {
    setVisible(true);
  };

  const content = (
    <ServicesTextContainer>
      {filteredArr()?.map((item) => (
        <div className="servicesText" key={item}>
          {item}
        </div>
      ))}
    </ServicesTextContainer>
  );
  const rolesContent = (
    <ServicesTextContainer>
      {apartmentData?.houseroles[0]?.custom_home_rules?.map((item) => (
        <div className="servicesText" key={item}>
          {item}
        </div>
      ))}
    </ServicesTextContainer>
  );

  const onBackClick = () => {
    navigate("/");
  };

  return (
    <LeftContainer>
      <button className="backButton" onClick={onBackClick}>
        <LeftIcon /> {t("booking.back")}
      </button>
      <TitleContainer>{apartmentData?.title}</TitleContainer>
      <ImagesContainer>
        <>
          <Image
            preview={{
              visible: false,
            }}
            height={310}
            src={apartmentData?.images[0].path}
            onClick={onImagePrevue}
          />
          <div
            style={{
              display: "none",
            }}
          >
            <Image.PreviewGroup
              preview={{
                visible,
                onVisibleChange: (visible) => setVisible(visible),
              }}
            >
              {apartmentData?.images?.map((item) => (
                <Image src={item.path} key={item.id} width={500} />
              ))}
            </Image.PreviewGroup>
          </div>
        </>
        <RightImgContainer
          display={apartmentData?.images.length <= 1 ? "none" : "flex"}
        >
          <div
            style={
              apartmentData?.images[1]
                ? { backgroundImage: `url(${apartmentData?.images[1].path})` }
                : null
            }
          />
          <div
            style={
              apartmentData?.images[2]
                ? { backgroundImage: `url(${apartmentData?.images[2].path})` }
                : null
            }
          />
          <div
            style={
              apartmentData?.images[3]
                ? { backgroundImage: `url(${apartmentData?.images[3].path})` }
                : null
            }
          />
          <div
            style={
              apartmentData?.images[4]
                ? { backgroundImage: `url(${apartmentData?.images[4].path})` }
                : null
            }
          >
            {apartmentData?.images.length > 5 ? (
              <div
                className={
                  apartmentData?.images.length <= 5 ? "hide" : "moreImages"
                }
              >
                + {apartmentData?.images.length - 5}
              </div>
            ) : null}
          </div>
        </RightImgContainer>
      </ImagesContainer>
      <InfoContainer>
        <Row1>
          <div className="iconContainer">
            <BedroomIcon />
          </div>
          <div className="infoText">
            {" "}
            {apartmentData?.bedrooms} {t("apartment_card.bedrooms")}
          </div>
          <div className="iconContainer">
            <MaxPersonIcon className="maxPerson" />
          </div>
          <div className="infoText">
            {t("apartment_card.max")} {apartmentData?.max_persons}
          </div>
        </Row1>
        <Row2>
          <div className="areaWrapper">
            <div className="iconContainer">
              <AreaIcon />
            </div>

            <div className="infoText">
              {apartmentData?.area} {t("apartment_card.area")}
            </div>
          </div>

          <div className="row2Container">
            <div className="infoText floor">
              {t("apartment_card.floor")} {apartmentData?.num_floors}
            </div>
            {!apartmentData?.elevator ? (
              <div className="elevatorWrapper ">
                <ElevatorIcon />
                <div className="infoText elevator">
                  {t("apartment_card.elevator")}
                </div>
                <div className="line"></div>
              </div>
            ) : (
              <div className="elevatorWrapper activeElevator ">
                <ElevatorIcon />
                <div className="infoText activeElevatorText ">
                  {t("apartment_card.elevator")}
                </div>
              </div>
            )}
          </div>
        </Row2>
        <Row3>
          {onDeleteServiced()}
          {filteredArr()?.length > 0 ? (
            <Popover content={content} title={t("apartment_card.all_services")}>
              <AllServicesButton>+{filteredArr()?.length}</AllServicesButton>
            </Popover>
          ) : null}
        </Row3>
      </InfoContainer>
      <HomeRolesContainer>
        <div className="homeTitle">{t("apartment_card.home_roles")}</div>
        <div className="checkInOut">
          <div className="checkIn">
            {t("apartment_card.check-in_after")}{" "}
            {moment(
              apartmentData?.houseroles[0]?.start_of_lease,
              "HH:mm"
            ).format(formatTime)}
          </div>
          <div className="checkOut">
            {t("apartment_card.check-in_before")}{" "}
            {moment(apartmentData?.houseroles[0].end_of_lease, "HH:mm").format(
              formatTime
            )}
          </div>
        </div>
        <div className="roles">
          {apartmentData?.houseroles[0].quiet_hours ? (
            <div>
              <SlipIcon /> {t("apartment_card.quiet_hours")}
            </div>
          ) : null}
          {apartmentData?.houseroles[0].self_check_in ? (
            <div>
              <SelfCheckIn /> {t("apartment_card.self_check-in")}
            </div>
          ) : null}
          {apartmentData?.houseroles[0].no_smoking ? (
            <div>
              <NoSmokingIcon /> {t("apartment_card.no_smoking")}
            </div>
          ) : null}

          {apartmentData?.houseroles[0].no_children ? (
            <div>
              <NoChildrenIcon /> {t("apartment_card.no_children")}
            </div>
          ) : null}

          {apartmentData?.houseroles[0].no_parties ? (
            <div>
              <NoPartiesIcon />
              {t("apartment_card.no_parties_events")}
            </div>
          ) : null}
          {apartmentData?.houseroles[0].no_pets ? (
            <div>
              <NoPetsIcon /> {t("apartment_card.no_pets")}
            </div>
          ) : null}
          {apartmentData?.houseroles[0].custom_home_rules?.length > 0 ? (
            <Popover
              content={rolesContent}
              title={t("apartment_card.all_roles")}
            >
              <div>
                +{apartmentData?.houseroles[0].custom_home_rules?.length}
              </div>
            </Popover>
          ) : null}
        </div>
        <div className="prepayment">
          {t("apartment_card.prepayment")}{" "}
          {apartmentData?.propertyes[0].prepayment}%
        </div>
      </HomeRolesContainer>
    </LeftContainer>
  );
};

export default ApartmentCardLeft;
