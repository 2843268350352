import styled from "styled-components";

export const PersonModalContainer = styled.div``;
export const PersonModalContent = styled.div`
  width: 100%;
  .text {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #3486eb;
  }

  .buttonsWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .incrementDecrement {
    border: none;
    background-color: white;
  }
`;

export const ModalItemsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;

  display: flex;
  flex-direction: row;
  align-items: center;

  input {
  }

  button {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    border-radius: 0px;
    cursor: pointer;
  }

  .applyButton {
    background: #3486eb;
    color: #ffffff;
    border: 0.5px solid #3486eb;
    margin: 0px 20px;
  }

  .resetButton {
    color: #494949;
    border: 1px solid #494949;
  }
`;

export const ChildrenAgeContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px 0px;
  .childrenAge {
    margin-bottom: 6px;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #494949;
  }
`;

export const SelectsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  .ant-select {
    margin: 5px;
  }
`;
