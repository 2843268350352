import styled from "styled-components";

export const DailyRentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const MainContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  position: relative;
  top: ${(props) => `${props.topMain}`};
  width: 100%;
  padding-top: 17px;
  background-color: #e5e5e5;
  right: 0;

  .contentWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #e5e5e5;
    position: relative;
    top: ${(props) => `${props.top}`};
    width: 100%;
  }

  .cardWrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: ${(props) => `${props.align}`};
    flex-direction: column;
  }

  .ant-pagination {
    margin: 40px 0px;
  }

  .ant-pagination-item-link {
    border: none;
    background: none;
  }

  .ant-pagination-item {
    border: none;
    background: none;
  }

  @media (max-width: 768px) {
    position: relative;
    top: 135px;

    .contentWrapper {
      position: relative;
      top: ${(props) => `${props.top}`};
      width: 100%;
    }
  }
`;

export const FilterContainer = styled.div`
  width: 100%;
`;

export const CardsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: ${(props) => `${props.align}`};
  min-height: 970px;

  @media (max-width: 1024px) {
    align-items: center;
    position: relative;
  }

  @media (max-width: 768px) {
    align-items: center;
    position: relative;
  }
`;

export const BottomContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
