import styled from "styled-components";

export const DateModalContainer = styled.div`
  padding-bottom: 10px;

  .rdrStaticRangeSelected {
    font-weight: 400;
  }

  .rdrDefinedRangesWrapper {
    background-color: #f9fafa;
  }

  .rdrStaticRange {
    background-color: #f9fafa;
  }

  @media (max-width: 920px) {
    .rdrMonths {
      display: flex;
      flex-direction: column;
    }
  }

  @media (max-width: 768px) {
    .rdrMonths {
      display: flex;
      flex-direction: column;
    }
  }

  @media (max-width: 430px) {
    .rdrMonth {
      width: 100%;
    }

    .rdrDefinedRangesWrapper {
      width: 100%;
    }
    .rdrDateRangePickerWrapper {
      display: flex;
      flex-direction: column;
    }
  }

  .daysCountContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .dateRang {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #bdbdbd;
  }

  .bottomContainer {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
    gap: 15px;

    .ant-btn {
      background: #3486eb;
      color: #ffffff;
      border: 0.5px solid #3486eb;
      margin: 0px 20px;
      height: 35px;
    }
  }
`;
export const DaysCount = styled.div`
  display: flex;
  justify-content: flex-end;
  border: 1px solid #bdbdbd;
  width: 250px;
  height: 35px;
  display: flex;
  align-items: center;
  padding: 5px;
  margin-top: 5px;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #3486eb;

  .leftText {
    margin-right: 10px;
  }

  @media (max-width: 430px) {
    width: 180px;
  }
`;
export const RadioButton = styled.div`
  border: none;
  background: none;
`;
