import styled from "styled-components";

export const ApartmentListContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 32px 30px;
  border-top: 1px solid #bdbdbd;
  width: 100%;
`;

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 9px 13px 15px;
  background-color: #ffffff;
  margin-right: 20px;
  cursor: pointer;

  .title {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
    margin-top: 8px;
  }

  .location {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #7c8083;
    display: flex;
    align-items: center;
    margin: 16px 0px;

    div {
      width: 224px;
    }

    svg {
      margin-right: 10px;
    }
  }

  .info {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #7c8083;
    display: flex;
    flex-direction: row;

    div {
      display: flex;
      align-items: center;
      svg {
        margin-right: 15px;
      }
    }
    .maxPerson {
      margin-right: 35px;
    }
  }

  @media (max-width: 1600px) {
    margin-right: 15px;
  }

  @media (max-width: 430px) {
    div {
      width: 195px;
    }
    .location {
      div {
        width: 115px;
      }
    }
  }

  @media (max-width: 375px) {
    div {
      width: 155px;
    }
    .info {
      .maxPerson {
        margin-right: 15px;
      }
    }
  }
`;

export const Image = styled.div`
  display: flex;
  flex-direction: row;
  background-image: ${(props) => `url(${props.url})`};
  width: 259px;
  height: 180px;
  background-repeat: no-repeat;
  background-size: cover;

  @media (max-width: 430px) {
    width: 230px;
  }

  @media (max-width: 430px) {
    width: 230px;
  }
`;

export const SelectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 9px 13px 15px;
  background-color: #ffffff;
  margin-right: 10px;
  width: 300px;

  .ant-skeleton {
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    width: 250px;

    .ant-skeleton-image {
      width: 150px;
    }
  }

  @media (max-width: 430px) {
    .ant-skeleton {
      width: 150px;
      .ant-skeleton-image {
        width: 130px;
      }
    }
  }
`;

export const CustomButton = styled.button`
  display: flex;
  cursor: pointer;
  border: none;
  background: none;
  margin-right: 20px;

  :hover {
    width: 68px;
    height: 200px;
    background-color: #eeeeee;
    display: flex;
    align-items: center;
  }

  :disabled {
    display: none;
  }

  svg {
    width: 56px;
    height: 56px;
    g path {
      fill: #7c8083;
    }
  }

  @media (max-width: 1600px) {
    margin-right: 15px;
  }

  @media (max-width: 1400) {
    margin-right: 0px;
  }

  @media (max-width: 375px) {
    margin-right: 5px;

    svg {
      width: 40px;
      height: 40px;
    }
  }
`;
