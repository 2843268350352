import React from "react";
import BookingSuccessfulImage from "../../assets/icon/BookingSuccessfulImage.png";

import { ReactComponent as TelegramIcon } from "../../assets/icon/TelegramIcon.svg";
import { ReactComponent as EmailIcon } from "../../assets/icon/EmailIcon.svg";
import { ReactComponent as ViberIcon } from "../../assets/icon/ViberIcon.svg";
import { ReactComponent as WatsUpIcon } from "../../assets/icon/WatsUpIcon.svg";
import { ReactComponent as PhoneIcon } from "../../assets/icon/PhoneIcon.svg";

import { BookingSuccessfulContainer, ImageContainer } from "./styled";
import { useTranslation } from "react-i18next";

const BookingSuccessful = () => {
  const { t } = useTranslation();
  return (
    <BookingSuccessfulContainer>
      <ImageContainer src={BookingSuccessfulImage}></ImageContainer>
      <div className="text">
        {t("booking_successful.thank_you_for_request")}
      </div>
      <div className="phonNumbersContainer">
        <div className="phonNumbers">
          <PhoneIcon />
          +374 98 752072
        </div>
        <div className="phonNumbers">
          <PhoneIcon />
          +374 41 444 282
        </div>
        <div className="phonNumbers">
          <PhoneIcon />
          +374 55 012 102
        </div>
      </div>
      <div className="messengersIcon">
        <a
          href="https://api.whatsapp.com/send/?phone=+37441444282"
          data-action="share/whatsapp/share"
          target="_blank"
          rel="noreferrer"
        >
          <WatsUpIcon />
        </a>
        <a
          href="viber://chat?number=%2B37441444282"
          target="_blank"
          rel="noreferrer"
        >
          <ViberIcon />
        </a>
        <a
          href="https://telegram.me/+37441444282"
          target="_blank"
          rel="noreferrer"
        >
          <TelegramIcon />
        </a>
        <a href="mailto:daily@mystreet.am">
          <EmailIcon />
        </a>
      </div>
    </BookingSuccessfulContainer>
  );
};

export default BookingSuccessful;
