import styled from "styled-components";

export const Wrapper = styled.div`
  .map_info {
    h2 {
      font-size: 12px;
    }
    p {
      font-size: 12px;
      font-weight: 400;
    }
  }
  .map_img {
    display: flex;
    justify-content: center;
    width: 255px;
    height: 150px;
    margin: 10px 0px;
  }
`;
