import { gql } from "@apollo/client";

export const CREATE_CUSTOMER = gql`
  mutation ($house_id: String!, $input: addCustomer) {
    addHouseCustomer(house_id: $house_id, input: $input) {
      adults
      children
      content
      departure_time
      email
      end_of_reservation
      infant
      name
      start_of_reservation
      status
      tel
      telegram
      time_of_entry
      viber
      whatsapp
      check_in_rules
    }
  }
`;
