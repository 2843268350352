import styled from "styled-components";

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  width: 100%;
  z-index: 100;
  background-color: #ffffff;
  padding: 0px 130px 0px 120px;
  border-bottom: 1px solid #bdbdbd;

  .burgerIcon {
    display: none;
  }

  @media (max-width: 1024px) {
    padding: ${(props) => `${props.padding}`};
  }

  @media (max-width: 768px) {
    padding: 15px 50px 70px 40px;
  }

  @media (max-width: 430px) {
    padding: 15px 30px 70px 30px;
    .burgerIcon {
      display: block;
    }
  }

  @media (max-width: 375px) {
    padding: 15px 10px 70px 10px;
  }
`;

export const RoutContainer = styled.div`
  display: flex;
  flex-direction: row;

  a {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #494949;
    height: 20px;

    span {
      margin-right: 10px;
    }
  }

  .dailyRent {
    margin-right: 30px;
  }

  a svg {
    margin-right: 10px;
  }

  .active {
    a {
      color: #3486eb;

      svg path {
        fill: #3486eb;
      }
    }
  }

  @media (max-width: 768px) {
    position: absolute;
    top: 90px;
    left: ${(props) => `${props.left}`};
  }

  @media (max-width: 430px) {
    position: absolute;
    top: 90px;
    left: 25px;
    display: none;
  }

  @media (max-width: 375px) {
    position: absolute;
    top: 90px;
    left: 10px;

    .dailyRent {
      margin-right: 10px;
    }
  }

  @media (max-width: 320px) {
    position: absolute;
    top: 90px;
    left: 4px;

    .dailyRent {
      margin-right: 8px;
    }

    a svg {
      margin-right: 5px;
    }
  }
`;

export const LanguageContainer = styled.div`
  .ant-select-selection-item {
    color: #3486eb;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
  }

  .ant-select-arrow {
    svg path {
      fill: #3486eb;
    }
  }
`;
export const CustomButton = styled.div`
  border: none;
  background: none;
  cursor: pointer;
  width: 100px;
  height: 80px;

  svg {
    width: 100px;
    height: 80px;
  }
`;

export const LinksPopover = styled.div`
  display: none;
  display: flex;
  gap: 10px;

  @media (max-width: 430px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    a {
      text-decoration: none;
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 17px;
      color: #494949;
      display: flex;
      align-items: center;
      gap: 10px;
    }
  }
`;
