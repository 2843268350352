import moment from "moment";

export const calculateEstimatedCost = (
  house,
  start_filter_date,
  end_filter_date
) => {
  const daysInRange =
    moment(end_filter_date).diff(moment(start_filter_date), "days") || 1;
  const houseDays = new Set();
  let estimatedCost = 0;
  let totalCost = 0;
  let priceCount = 0;

  if (house?.prices && house?.prices.length > 0) {
    house.prices.forEach((price) => {
      const priceStart = moment.max(
        moment(price.start_date),
        moment(start_filter_date)
      );
      const priceEnd = moment.min(
        moment(price.end_date),
        moment(end_filter_date)
      );
      const priceDaysInRange = priceEnd.diff(priceStart, "days") + 1;
      const pricePerNight = price.price || house?.amount;
      const discount = price.discount || 0;
      const costPerNight = pricePerNight - pricePerNight * (discount / 100);

      for (let i = 0; i < priceDaysInRange; i++) {
        const priceDay = moment(priceStart).add(i, "days").format("YYYY-MM-DD");
        houseDays.add(priceDay);
      }

      totalCost += costPerNight * priceDaysInRange;
      priceCount += priceDaysInRange;
    });

    const unmatchedDays = daysInRange - houseDays.size;
    if (unmatchedDays > 0) {
      totalCost += house?.amount * unmatchedDays;
      priceCount += unmatchedDays;
    }

    const avgPricePerNight = totalCost / priceCount;
    const notDiscountPrice = house?.amount * daysInRange;
    estimatedCost = Math.round(avgPricePerNight) * daysInRange;
    const discount =
      ((notDiscountPrice - estimatedCost) / notDiscountPrice) * 100;
    return {
      pricePerNight: Math.round(avgPricePerNight),
      estimatedCost: Math.round(estimatedCost),
      totalDiscount: parseFloat(discount.toFixed(2)),
    };
  } else {
    const pricePerNight = house?.amount;
    const discount = 0;
    estimatedCost = pricePerNight * daysInRange;
    return {
      pricePerNight,
      estimatedCost,
      totalDiscount: discount,
    };
  }
};
