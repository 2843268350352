import { gql } from "@apollo/client";

export const GET_ALL_APARTAMENT_INFO = gql`
  query ($status: [String], $page: Int, $size: Int) {
    getAllHomesInfo(status: $status, page: $page, size: $size) {
      totalPages
      totalItems
      currentPage
      data {
        id
        area
        elevator
        title
        internal_location
        amount
        location
        area
        prices {
          discount
          price
          start_date
          end_date
          notes
          id
        }
        max_persons
        bedrooms
        updatedAt
        createdAt
        num_floors
        propertyes {
          id
          prepayment
          services
        }
        houseroles {
          id
          self_check_in
          end_of_lease
          start_of_lease
          no_pets
          no_smoking
          no_children
        }
        images {
          id
          name
          type
          path
        }
        discount
      }
      filteredData {
        erevan {
          address
        }
        tsaghkadzor {
          address
        }
      }
    }
  }
`;

export const GET_TOTAL_ITEMS = gql`
  query ($status: [String], $page: Int, $size: Int) {
    getAllHomesInfo(status: $status, page: $page, size: $size) {
      totalItems
    }
  }
`;

export const GET_SINGLE_APARTAMENT_INFO = gql`
  query ($house_id: String!) {
    getSingleHomeInfo(house_id: $house_id) {
      id
      area
      elevator
      amount
      title
      internal_location
      location
      area
      max_persons
      bedrooms
      updatedAt
      createdAt
      num_floors
      pricePerNight
      estimatedCost
      totalDiscount
      notDiscountPrice
      discount
      propertyes {
        id
        prepayment
        services
      }
      houseroles {
        custom_home_rules
        id
        end_of_lease
        start_of_lease
        self_check_in
        no_parties
        no_pets
        no_smoking
        quiet_hours
        no_children
      }
      images {
        id
        name
        type
        path
      }
      prices {
        discount
        price
        start_date
        end_date
        notes
        id
      }
      discount
    }
  }
`;

export const GET_SINGLE_APARTMENT_AMOUNT = gql`
  query ($house_id: String!) {
    getSingleHomeInfo(house_id: $house_id) {
      amount
      max_persons
      title
      reservedDates {
        start
        end
      }
      houseroles {
        id
        self_check_in
        end_of_lease
        start_of_lease
        no_pets
        no_smoking
        no_children
      }
      prices {
        discount
        price
        start_date
        end_date
        notes
        id
      }
    }
  }
`;

export const SEARCH_IN_APARTMENT_BY_TITLE = gql`
  query ($search: String!, $size: Int, $page: Int) {
    searchInHouses(search: $search, size: $size, page: $page) {
      totalItems
      totalPages
      currentPage
      data {
        id
        area
        elevator
        title
        amount
        location
        area
        max_persons
        bedrooms
        updatedAt
        createdAt
        num_floors
        propertyes {
          id
          prepayment
          services
        }
        houseroles {
          id
          self_check_in
          end_of_lease
          start_of_lease
          no_pets
          no_smoking
          no_children
        }
        images {
          id
          name
          type
          path
        }
      }
    }
  }
`;
export const FILTER_APARTMENT_BY_FILLES = gql`
  query ($filterInput: filter, $page: Int, $size: Int) {
    getAllHomesInfo: filterInHouses(
      filterInput: $filterInput
      size: $size
      page: $page
    ) {
      totalItems
      totalPages
      currentPage
      data {
        id
        area
        elevator
        title
        internal_location
        amount
        location
        area
        max_persons
        bedrooms
        updatedAt
        createdAt
        num_floors
        propertyes {
          id
          prepayment
          services
        }
        houseroles {
          id
          self_check_in
          end_of_lease
          start_of_lease
          no_pets
          no_smoking
          no_children
        }
        images {
          id
          name
          type
          path
        }
        pricePerNight
        estimatedCost
        totalDiscount
        notDiscountPrice
        discount
      }
    }
  }
`;
