import React, { useEffect, useState } from "react";
import {
  TimePicker,
  DatePicker,
  Input,
  Button,
  Checkbox,
  Form,
  InputNumber,
  message,
} from "antd";
import moment from "moment";
import { ReactComponent as LeftIcon } from "../../assets/icon/LeftIcon.svg";
import { formatTime } from "../../utils/constants";
import { CREATE_CUSTOMER } from "../Graphql/mutation";
import { useMutation, useQuery } from "@apollo/client";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { GET_SINGLE_APARTMENT_AMOUNT } from "../Graphql/query";

import {
  BookingContainer,
  DateContainer,
  PriceContainer,
  CardTitleContainer,
  ButtonContainer,
  PersonsCountContainer,
  Price,
} from "./styled";
import { useTranslation } from "react-i18next";
import { calculateEstimatedCost } from "../../utils/calculatePrise";
import Contacts from "./Contacts";

const { TextArea } = Input;

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const Booking = () => {
  const { id: apartmentId } = useParams();
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const location = useLocation();
  const [createCustomerInfo, setCreateCustomerInfo] = useState({
    adults: 1,
    children: 0,
    content: "",
    deposit: 0,
    email: "",
    infant: 0,
    name: "",
    status: "draft",
    tel: { code: "", number: "" },
    telegram: { code: "", number: "" },
    time_of_entry: moment("00:00", formatTime).format(formatTime),
    departure_time: moment("00:00", formatTime).format(formatTime),
    viber: { code: "", number: "" },
    whatsapp: { code: "", number: "" },
    check_in_rules: false,
  });
  const [dateRange, setDateRange] = useState(0);
  const [maxPersons, setMaxPersons] = useState(0);

  const {
    state: {
      dataState,
      personsCount,
      totaledPriceState,
      dateRange: dateRangeState,
    },
  } = location;

  const { data } = useQuery(GET_SINGLE_APARTMENT_AMOUNT, {
    variables: { house_id: apartmentId },
  });

  const [createdCustomer, { data: responseData, loading: responseLoading }] =
    useMutation(CREATE_CUSTOMER);

  useEffect(() => {
    setMaxPersons(data?.getSingleHomeInfo?.max_persons);
  }, [data?.getSingleHomeInfo?.max_persons]);

  useEffect(() => {
    setCreateCustomerInfo({
      ...createCustomerInfo,
      adults: personsCount.adults === 0 ? 1 : personsCount.adults,
      children: personsCount.children,
      infant: personsCount.infant,
      end_of_reservation: moment(dataState[0]?.endDate).format("YYYY-MM-DD"),
      start_of_reservation: moment(dataState[0]?.startDate).format(
        "YYYY-MM-DD"
      ),
    });
  }, [location]);

  useEffect(() => {
    setDateRange(
      createCustomerInfo.end_of_reservation &&
        createCustomerInfo.start_of_reservation
        ? Math.floor(
            (new Date(createCustomerInfo.end_of_reservation) -
              new Date(createCustomerInfo.start_of_reservation)) /
              (1000 * 3600 * 24)
          )
        : 0
    );
  }, [
    createCustomerInfo.end_of_reservation,
    createCustomerInfo.start_of_reservation,
  ]);

  const onBackClick = () => {
    navigate(-1);
  };

  const onDateChange = (date, dateString, name) => {
    setCreateCustomerInfo({
      ...createCustomerInfo,
      [name]: dateString,
    });
  };

  const onTimeChange = (date, dateString, name) => {
    setCreateCustomerInfo({
      ...createCustomerInfo,
      [name]: dateString,
    });
  };

  const onInputChange = (event, name) => {
    setCreateCustomerInfo({
      ...createCustomerInfo,
      [name]: event.target.value,
    });
  };

  const onNumberInputChange = (value, name) => {
    setCreateCustomerInfo({
      ...createCustomerInfo,
      [name]: value,
    });
  };

  const onCheckboxChange = (value) => {
    setCreateCustomerInfo({
      ...createCustomerInfo,
      check_in_rules: value.target.checked,
    });
  };

  const onFinish = () => {
    createdCustomer({
      variables: { house_id: apartmentId, input: createCustomerInfo },
      onError: (error) => {
        message.error(error.message);
      },
      onCompleted: () => {
        navigate("/booking-successful");
      },
    });
  };

  const disabledDateRage = (current) => {
    const disableRange = data?.getSingleHomeInfo?.reservedDates.map((range) => {
      return (
        current >
          moment(
            moment(+range.start - 86000000).format("YYYY-MM-DD"),
            "YYYY-MM-DD"
          ) &&
        current <=
          moment(moment(+range.end).format("YYYY-MM-DD"), "YYYY-MM-DD").endOf(
            "day"
          )
      );
    });
    return disableRange.find((range) => (range === true ? true : false));
  };

  const disabledStartTime = () => {
    var hours = [];
    for (
      var i = 0;
      i <
      moment(
        data?.getSingleHomeInfo?.houseroles[0]?.start_of_lease,
        "HH:mm:ss"
      ).hour();
      i++
    ) {
      hours.push(i);
    }
    return hours;
  };

  const disabledEndTime = () => {
    var hours = [];
    for (
      var i = 0;
      i <
      moment(
        data?.getSingleHomeInfo?.houseroles[0]?.end_of_lease,
        "HH:mm:ss"
      ).hour();
      i++
    ) {
      hours.push(i);
    }
    return hours;
  };
  const { pricePerNight, estimatedCost } = calculateEstimatedCost(
    data?.getSingleHomeInfo,
    createCustomerInfo.start_of_reservation,
    createCustomerInfo.end_of_reservation
  );
  return (
    <div
      style={{
        backgroundColor: "#F9F9F9",
        width: "100%",
        height: "135vh",
      }}
    >
      <BookingContainer>
        <Form
          {...layout}
          onFinish={onFinish}
          form={form}
          name="form"
          autoComplete="off"
          initialValues={{
            startPicker:
              moment(dataState[0]?.startDate).format("YYYY-MM-DD") !==
                moment(new Date()).format("YYYY-MM-DD") ||
              moment(dataState[0]?.endDate).format("YYYY-MM-DD") !==
                moment(new Date()).format("YYYY-MM-DD")
                ? moment(dataState[0]?.startDate)
                : null,
            endPicker:
              moment(dataState[0]?.endDate).format("YYYY-MM-DD") !==
              moment(new Date()).format("YYYY-MM-DD")
                ? moment(dataState[0]?.endDate)
                : null,
          }}
        >
          <CardTitleContainer>
            <button className="backButton" onClick={onBackClick}>
              <LeftIcon /> {t("booking.back")}
            </button>
            <div>{data?.getSingleHomeInfo?.title}</div>
          </CardTitleContainer>
          <div className="contactInfo">{t("booking.dates")}</div>
          <div className="changeDays">
            {!dateRange ? 0 : dateRange} {t("booking.night")}
          </div>
          <DateContainer>
            <Form.Item
              name="startPicker"
              rules={[
                {
                  type: "object",
                  required: true,
                  message: t("booking.please_select_time"),
                },
              ]}
            >
              <DatePicker
                onChange={(date, dateString) =>
                  onDateChange(date, dateString, "start_of_reservation")
                }
                defaultValue={createCustomerInfo.start_of_reservation}
                disabledDate={(current) => disabledDateRage(current)}
                placeholder={t("booking.select_date_check_in")}
              />
            </Form.Item>
            <Form.Item
              name="endPicker"
              rules={[
                {
                  type: "object",
                  required: true,
                  message: t("booking.please_select_time"),
                },
              ]}
            >
              <DatePicker
                onChange={(date, dateString) =>
                  onDateChange(date, dateString, "end_of_reservation")
                }
                defaultValue={createCustomerInfo.end_of_reservation}
                disabledDate={(current) => disabledDateRage(current)}
                placeholder={t("booking.select_date_check_out")}
              />
            </Form.Item>
          </DateContainer>
          <DateContainer>
            <div className="timePicker">
              <TimePicker
                onChange={(date, dateString) =>
                  onTimeChange(date, dateString, "time_of_entry")
                }
                defaultValue={moment(createCustomerInfo.time_of_entry, formatTime)}
                format={formatTime}
                disabledHours={disabledStartTime}
              />
              <TimePicker
                onChange={(date, dateString) =>
                  onTimeChange(date, dateString, "departure_time")
                }
                defaultValue={moment(createCustomerInfo.departure_time, formatTime)}
                format={formatTime}
                disabledHours={disabledEndTime}
              />
            </div>
          </DateContainer>
          <div className="contactInfo">{t("booking.guests")}</div>
          <PersonsCountContainer>
            <div className="left">
              <div className="text">{t("booking.adults")}</div>
              <InputNumber
                type="number"
                placeholder={t("booking.adults")}
                value={createCustomerInfo?.adults}
                max={maxPersons - createCustomerInfo?.children}
                min={0}
                onChange={(value) => onNumberInputChange(value, "adults")}
                style={{
                  width: "100%",
                }}
              />
            </div>
            <div className="right">
              <div className="text">{t("booking.children")} (2-12) </div>
              <InputNumber
                type="number"
                placeholder={`${t("booking.children")} (2-12)`}
                value={createCustomerInfo?.children}
                max={maxPersons - createCustomerInfo?.adults}
                min={0}
                onChange={(value) => onNumberInputChange(value, "children")}
                style={{
                  width: "100%",
                }}
              />
            </div>
          </PersonsCountContainer>
          <PersonsCountContainer>
            <div className="left">
              <div className="text">{t("booking.infants")} (0-2)</div>
              <InputNumber
                type="number"
                placeholder={t("booking.infants")}
                min={0}
                value={createCustomerInfo?.infant}
                onChange={(value) => onNumberInputChange(value, "infant")}
                style={{
                  width: "100%",
                }}
              />
            </div>
          </PersonsCountContainer>
          <PriceContainer>
            <div className="priceLeft">
              <div className="priceText"> {t("booking.cost")} </div>
              <Price>
                {dateRange ? dateRange : dateRangeState ? dateRangeState : 0}{" "}
                {t("booking.night")} *{" "}
                {!!totaledPriceState?.pricePerNight
                  ? totaledPriceState?.pricePerNight
                  : pricePerNight}{" "}
                $
              </Price>
            </div>
            <div className="priceRight">
              <div className="priceText"> {t("booking.total")}</div>
              <Price>
                <div>{t("booking.total_cost")}</div>
                <div>
                  {dateRange
                    ? estimatedCost
                    : totaledPriceState?.estimatedCost
                    ? totaledPriceState?.estimatedCost
                    : 0}{" "}
                  $
                </div>
              </Price>
            </div>
          </PriceContainer>

          <Contacts
            createCustomerInfo={createCustomerInfo}
            onInputChange={onInputChange}
            setCreateCustomerInfo={setCreateCustomerInfo}
          />
          <div className="wishesOwner">{t("booking.wishes_owner")}</div>
          <TextArea
            rows={4}
            placeholder={t("booking.type_here")}
            autoSize={true}
            value={createCustomerInfo?.content}
            onChange={(event) => onInputChange(event, "content")}
          />
          <ButtonContainer>
            <Form.Item>
              <Button
                className="requestButton"
                htmlType="submit"
                disabled={responseLoading}
              >
                {t("booking.request_button")}
              </Button>
            </Form.Item>
            <Form.Item
              name="agree"
              valuePropName="checked"
              rules={[
                {
                  validator: (_, value) =>
                    value
                      ? Promise.resolve()
                      : Promise.reject(
                          new Error(t("booking.agree_home_rules"))
                        ),
                },
              ]}
            >
              <Checkbox onChange={onCheckboxChange}>
                {t("booking.selecting_button_agree_home_rules")}
              </Checkbox>
            </Form.Item>
          </ButtonContainer>
        </Form>
      </BookingContainer>
    </div>
  );
};

export default Booking;
