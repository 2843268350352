import React, { useState } from "react";
import { AutoComplete, Button, Select } from "antd";
import moment from "moment";
import PersonCountModal from "../PersonCountModal";
import DateModal from "../DateModal";
import {
  filterDateFormat,
  filterDateFormatRequest,
} from "../../utils/constants";

import { ReactComponent as CalendarIcon } from "../../assets/icon/CalendarIcon.svg";
import { ReactComponent as PersonsIcon } from "../../assets/icon/PersonsIcon.svg";
import { ReactComponent as AreaIcon } from "../../assets/icon/AreaIcon.svg";
import { ReactComponent as LocationIcon } from "../../assets/icon/LocationIcon.svg";

import {
  FilterContainer,
  ModalsButtonContainer,
  ButtonsContainer,
  CustomButton,
  AutoCompleteOption,
} from "./styled";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { addDays, endOfDay, startOfDay } from "date-fns";

const { Option } = Select;

const Filter = ({
  viewMap,
  setViewMap,
  searchData,
  setSearchData,
  houses,
  setSelectedSearchValue,
  setSelectValue,
  getFilteredApartment,
  filteredLoading,
  personsCount,
  setPersonsCount,
  dataState,
  setDataState,
  setCurrent,
  selectValue,
  refetch,
  selectedSearchValue,
  current,
  apartmentCount,
}) => {
  const [options, setOptions] = useState([]);
  const [isPersonCountModalOpen, setIsPersonCountModalOpen] = useState(false);
  const [isDateModalOpen, setIsDateModalOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const { t } = useTranslation();
  const location = useLocation();
  const comparDate =
    moment(endOfDay(addDays(new Date(), +1))).format("YYYY-MM-DD") !==
    moment(dataState[0].endDate).format("YYYY-MM-DD");

  const showPersonCountModal = () => {
    setIsPersonCountModalOpen(true);
  };

  const showDateModal = () => {
    setIsDateModalOpen(true);
  };

  const renderItem = (title, searchText) => {
    return {
      value: title,
      label: (
        <AutoCompleteOption>
          <span>{title === searchText ? <LocationIcon /> : <AreaIcon />}</span>
          {title}
        </AutoCompleteOption>
      ),
    };
  };

  const onSearch = (searchText) => {
    const optionData = searchData?.filter((item) =>
      item.address.toLowerCase().includes(searchText.toLowerCase())
    );
    const newOption = [{ address: searchText }, ...optionData];
    setOptions(
      newOption?.map((option) => ({
        ...renderItem(option.address, searchText),
      }))
    );
  };

  const onSelect = (data) => {
    setSelectedSearchValue(data);
    getFilteredApartment({
      variables: {
        filterInput: {
          search: selectedSearchValue,
          filter_city: selectValue,
          start_filter_date: comparDate
            ? moment(dataState[0]?.startDate).format(filterDateFormatRequest)
            : null,
          end_filter_date: comparDate
            ? moment(dataState[0]?.endDate).format(filterDateFormatRequest)
            : null,
          ...personsCount,
        },
      },
    });
    setCurrent(1);
  };

  const onAutoCompleteChange = (data) => {
    if (data === "") {
      setSelectedSearchValue("");
    }
    setSearchValue(data);
  };

  const handleSelectChange = (value) => {
    setSelectValue(value);
    if (value === "erevan" && !filteredLoading && houses) {
      setSearchData([...houses?.getAllHomesInfo?.filteredData?.erevan]);
    } else if (value === "tsaghkadzor" && !filteredLoading && houses) {
      setSearchData([...houses?.getAllHomesInfo?.filteredData?.tsaghkadzor]);
    } else {
      setSearchData([
        ...houses?.getAllHomesInfo?.filteredData?.tsaghkadzor,
        ...houses?.getAllHomesInfo?.filteredData?.erevan,
      ]);
    }
    getFilteredApartment({
      variables: {
        filterInput: {
          search: selectedSearchValue,
          filter_city: value,
          start_filter_date: comparDate
            ? moment(dataState[0]?.startDate).format(filterDateFormatRequest)
            : null,
          end_filter_date: comparDate
            ? moment(dataState[0]?.endDate).format(filterDateFormatRequest)
            : null,
          ...personsCount,
        },
      },
    });
    setSearchValue("");
    setSelectedSearchValue("");
    setOptions([]);
    setCurrent(1);
  };

  const onSearchClick = () => {
    getFilteredApartment({
      variables: {
        filterInput: {
          search: selectedSearchValue,
          filter_city: selectValue,
          start_filter_date: comparDate
            ? moment(dataState[0]?.startDate).format(filterDateFormatRequest)
            : null,
          end_filter_date: comparDate
            ? moment(dataState[0]?.endDate).format(filterDateFormatRequest)
            : null,
          ...personsCount,
        },
      },
    });
  };

  const onResetClick = () => {
    getFilteredApartment({
      variables: {
        filterInput: {
          search: "",
          filter_city: "",
          start_filter_date: null,
          end_filter_date: null,
          adults: 1,
          children: 0,
          infant: 0,
          bedrooms: 0,
        },
      },
    });
    setPersonsCount({
      adults: 1,
      children: 0,
      infant: 0,
      bedrooms: 0,
    });
    setDataState([
      {
        startDate: startOfDay(new Date()),
        endDate: endOfDay(addDays(new Date(), +1)),
        key: "selection",
      },
    ]);
    setCurrent(1);
  };

  return (
    <FilterContainer>
      <Select
        value={
          selectValue === "" ? t("filter.all_apartment_select") : selectValue
        }
        onChange={handleSelectChange}
        className="selectCountry"
        getPopupContainer={(trigger) => trigger.parentNode}
      >
        <Option value="">{t("filter.all_apartment_select")}</Option>
        <Option value="erevan">{t("filter.yerevan_select")}</Option>
        <Option value="tsaghkadzor">{t("filter.tsaghkadzor_select")}</Option>
      </Select>

      {false ? (
        <AutoComplete
          value={searchValue}
          options={options}
          onSelect={onSelect}
          onSearch={onSearch}
          onChange={onAutoCompleteChange}
          placeholder={t("filter.armenia_yerevan")}
          getPopupContainer={(trigger) => trigger.parentNode}
        />
      ) : null}
      <ModalsButtonContainer>
        <CustomButton className="calendarButton" onClick={showDateModal}>
          <div className="iconWrapper">
            <CalendarIcon />
          </div>
          <div className="buttonText">
            <div className="topText">{t("filter.check_in_check_out")}</div>
            <div className="bottomText">{`${moment(
              dataState[0].startDate
            ).format(filterDateFormat)} - ${moment(dataState[0].endDate).format(
              filterDateFormat
            )}`}</div>
          </div>
        </CustomButton>
        <CustomButton onClick={showPersonCountModal}>
          <div className="iconWrapper">
            <PersonsIcon />
          </div>
          <div className="buttonText">
            <div className="topText">
              {personsCount.bedrooms} {t("filter.bedrooms")}{" "}
            </div>
            <div className="bottomText">{`${personsCount.adults} ${t(
              "filter.adults"
            )}, ${personsCount.children} ${t("filter.children")}`}</div>
          </div>
        </CustomButton>
      </ModalsButtonContainer>
      <ButtonsContainer>
        <Button className="searchButton" onClick={onSearchClick}>
          {t("filter.search_button")}
        </Button>
        {comparDate ||
        personsCount.adults > 1 ||
        personsCount.children ||
        personsCount.infant ? (
          <Button className="searchButton" onClick={onResetClick}>
            {t("filters_persons_count.reset_button")}
          </Button>
        ) : null}
        {location.pathname === "/" ? (
          <Button className="mapButton" onClick={() => setViewMap(!viewMap)}>
            {viewMap
              ? t("filter.view_list_button")
              : t("filter.view_map_button")}
          </Button>
        ) : null}
      </ButtonsContainer>
      <PersonCountModal
        isPersonCountModalOpen={isPersonCountModalOpen}
        setIsPersonCountModalOpen={setIsPersonCountModalOpen}
        personsCount={personsCount}
        setPersonsCount={setPersonsCount}
        getFilteredApartment={getFilteredApartment}
        refetch={refetch}
        selectedSearchValue={selectedSearchValue}
        current={current}
        apartmentCount={apartmentCount}
        dataState={dataState}
        selectValue={selectValue}
      />
      <DateModal
        isDateModalOpen={isDateModalOpen}
        setIsDateModalOpen={setIsDateModalOpen}
        setIsPersonCountModalOpen={setIsPersonCountModalOpen}
        dataState={dataState}
        setDataState={setDataState}
      />
    </FilterContainer>
  );
};

export default React.memo(Filter);
