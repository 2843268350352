import React from "react";
import ApartmentCardLeft from "../ApartmentCardLeft/index";
import ApartmentCardRight from "../ApartmentCardRight/index";

import { ApartmentCardContainer } from "./styled";

const ApartmentCard = ({ setViewBooking, data, personsCount, dataState, state }) => {
  return (
    <ApartmentCardContainer>
      <ApartmentCardLeft apartmentData={data?.getSingleHomeInfo} />
      <ApartmentCardRight
        setViewBooking={setViewBooking}
        apartmentData={data?.getSingleHomeInfo}
        personsCount={personsCount}
        dataState={dataState}
        state={state}
      />
    </ApartmentCardContainer>
  );
};

export default ApartmentCard;
