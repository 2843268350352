import styled from "styled-components";

export const CardContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border: 1px solid #bdbdbd;
  padding: 15px;
  margin-bottom: 10px;
  position: relative;
  width: ${(props) => `${props.width}`};
  background-color: #ffffff;

  @media (max-width: 1470px) {
    width: ${(props) => `${props.width1470}`};
  }

  @media (max-width: 1100px) {
    width: ${(props) => `${props.width1100}`};
  }

  @media (max-width: 1024px) {
    width: 100%;
  }

  @media (max-width: 768px) {
    width: 70%;
    height: auto;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
  }

  @media (max-width: 430px) {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  @media (max-width: 375px) {
    padding: 10px;
  }
`;

export const ImagContainer = styled.div`
  width: 25%;
  position: relative;

  :hover {
    .prevButton {
      display: block;
      position: absolute;
      top: 90px;
      left: 0;
    }

    .nextButton {
      display: block;
      position: absolute;
      top: 90px;
      right: 0;
    }
  }

  .prevButton {
    display: none;
  }

  .nextButton {
    display: none;
  }

  .slick-dots li button {
    width: ${(props) => `${props.width}`} !important;
    height: ${(props) => `${props.height}`} !important;
    border-radius: 100%;
  }

  @media (max-width: 768px) {
    width: 100%;
    .prevButton {
      display: block;
      position: absolute;
      top: 90px;
      left: 0;
    }

    .nextButton {
      display: block;
      position: absolute;
      top: 90px;
      right: 0;
    }
  }
`;

export const CustomButton = styled.button`
  border: none;
  background: none;
  cursor: pointer;
`;

export const Imag = styled.div`
  display: flex;
  flex-direction: row;
  background-image: ${(props) => `url(${props.url})`};
  width: 100%;
  height: 196px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`;

export const InfosContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0px 39px 15px 16px;
  width: 35%;

  .infoText {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #7c8083;
  }

  svg {
    width: 25px;
    height: 25px;
    margin-right: 20px;
  }

  @media (max-width: 768px) {
    width: 100%;
  }

  @media (max-width: 320px) {
    svg {
      margin-right: 12px;
    }
  }
`;

export const TermsLeaseContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  background-color: #f9f9f9;
  width: 35%;
  padding: 13px 8px 6px 8px;
  gap: 10px;

  .buttonsContainer {
    display: flex;
    flex-direction: row;
    button {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      color: #ffffff;
    }

    .orderNow {
      background-color: #e8f1ff;
      border: 1px solid #e8f1ff;
      color: #3486eb;
    }

    .viewDeal {
      background-color: #3486eb;
      display: flex;
      align-items: center;
      margin-left: 13px;
      border: 1px solid #3486eb;
      color: #ffffff;
      svg {
        margin-left: 16px;
      }
    }
  }

  .personsCount {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #494949;
  }

  .freeDays {
    display: flex;
    align-items: center;
    font-size: 12px;
    line-height: 14px;
    color: #3486eb;

    svg {
      margin-right: 7px;
      path {
        fill: #3486eb;
      }
    }
  }
  .nightPrice {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    font-size: 10px;
    line-height: 12px;
    color: #3e64ff;
    .price {
      font-weight: 900;
      font-size: 16px;
      line-height: 19px;
    }
  }

  .totalAmount {
    display: flex;
    flex-direction: row;
    gap: 10px;
  }

  .salePrice {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #494949;
  }

  .totalPrice {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #494949;
    margin-right: ${(props) => `${props.margin}`};
    position: relative;

    .line {
      position: absolute;
      top: 10px;
      width: 95px;
      border-top: 2px solid #ff6433;
      border-radius: 5px;
    }
  }

  @media (max-width: 1570px) {
    .buttonsContainer {
      display: flex;
      flex-direction: ${(props) => `${props.flex}`};

      .viewDeal {
        margin: ${(props) => `${props.margin_top}`};
      }
    }
  }

  @media (max-width: 768px) {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
`;

export const Title = styled.div`
  display: flex;
  flex-direction: row;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  color: #494949;

  @media (max-width: 768px) {
    margin-top: 20px;
  }
`;

export const SaleContainer = styled.div`
  width: 71px;
  height: 65px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #ff6433;
  border: 1px solid #ff6433;
  position: absolute;
  top: 0;
  right: 25%;
  div {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #ffffff;
  }
`;

export const Row1 = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid #d9d9d9;
  padding: 13px 25px 6px 0px;

  .address {
    width: 90%;
  }

  @media (max-width: 375px) {
    padding: 13px 0px 6px 0px;
  }
`;

export const Row2 = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  border-bottom: 1px solid #d9d9d9;
  padding: 12px 0px;
  gap: 15px;

  .bedroomContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .maxPersonContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  @media (max-width: 1594px) {
    .maxPersonContainer {
      display: flex;
      flex-direction: row;
      margin-top: ${(props) => `${props.top}`};
    }
  }

  @media (max-width: 375px) {
    padding: 13px 10px 6px 0px;
  }
`;

export const Row3 = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  border-bottom: 1px solid #d9d9d9;
  padding: 12px 25px 12px 0px;
  gap: 20px;

  .area {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .elevator {
    color: #e0e0e0;
    margin-bottom: 2px;
  }

  .elevatorWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    width: 76px;
    justify-content: center;
    margin-top: ${(props) => `${props.elevatorTop}`};

    svg {
      margin-right: 3px;
    }
  }
  .activeElevator {
    svg path {
      fill: #3486eb;
    }
  }

  .activeElevatorText {
    color: #3486eb;
  }

  .line {
    position: absolute;
    width: 100%;
    border-top: 2px solid #bdbdbd;
    border-radius: 5px;
  }

  @media (max-width: 875px) {
    .elevatorWrapper {
      margin-top: 10px;
    }
  }

  @media (max-width: 320px) {
    display: flex;
    flex-wrap: wrap;

    .elevatorWrapper {
      margin-top: 10px;
    }
  }
`;

export const Row4 = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 0px 0px 0px;

  svg g path {
    fill: #019734;
  }

  svg path {
    fill: #019734;
  }
`;

export const AllServicesButton = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #494949;
  border: none;
  background: none;
  cursor: pointer;
`;

export const ServicesTextContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 200px;

  .servicesText {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #7c8083;
    margin-right: 10px;
  }
`;
