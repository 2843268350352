import React from "react";
import { Radio } from "antd";
import { RadioButton } from "./styled";

import {
  addDays,
  endOfDay,
  startOfDay,
  startOfWeek,
  endOfWeek,
  isSameDay,
} from "date-fns";

const defineds = {
  startOfNextWeekend: endOfWeek(addDays(new Date(), 0)),
  endOfNextWeekend: startOfWeek(addDays(new Date(), +7)),
  startOfToday: startOfDay(new Date()),
  endOfToday: endOfDay(addDays(new Date(), +1)),
  startOfTomorrow: startOfDay(addDays(new Date(), +1)),
  endOfTomorrow: endOfDay(addDays(new Date(), +2)),
  startOfCustom: startOfDay(new Date()),
  endOfCustom: endOfDay(addDays(new Date(), +1)),
};

const staticRangeHandler = {
  range: {},
  isSelected(range) {
    const definedRange = this.range();
    return (
      isSameDay(range.startDate, definedRange.startDate) &&
      isSameDay(range.endDate, definedRange.endDate)
    );
  },
};

const createStaticRanges = (ranges) => {
  return ranges.map((range) => ({ ...staticRangeHandler, ...range }));
};

const onRadioClick = (e, setChecked, checked, name) => {
  setChecked({
    tonight: false,
    tomorrow: false,
    nextWeekend: false,
    custom: false,
    [name]: true,
  });
};

export const defaultStaticRanges = (dataState, checked, setChecked, t) =>
  createStaticRanges([
    {
      label: (
        <RadioButton
          onClick={(e) => onRadioClick(e, setChecked, checked, "tonight")}
        >
          <Radio checked={checked.tonight}>{t("date_modal.tonight")}</Radio>
        </RadioButton>
      ),
      range: () => ({
        startDate: defineds.startOfToday,
        endDate: defineds.endOfToday,
      }),
    },
    {
      label: (
        <RadioButton
          onClick={(e) => onRadioClick(e, setChecked, checked, "tomorrow")}
        >
          <Radio checked={checked.tomorrow}>
            {t("date_modal.tomorrow_night")}
          </Radio>
        </RadioButton>
      ),
      range: () => ({
        startDate: defineds.startOfTomorrow,
        endDate: defineds.endOfTomorrow,
      }),
    },
    {
      label: (
        <RadioButton
          onClick={(e) => onRadioClick(e, setChecked, checked, "nextWeekend")}
        >
          <Radio checked={checked.nextWeekend}>
            {t("date_modal.next_weekend")}
          </Radio>
        </RadioButton>
      ),
      range: () => ({
        startDate: defineds.startOfNextWeekend,
        endDate: defineds.endOfNextWeekend,
      }),
    },
    {
      label: (
        <RadioButton
          onClick={(e) => onRadioClick(e, setChecked, checked, "custom")}
        >
          <Radio checked={checked.custom}>{t("date_modal.custom")}</Radio>
        </RadioButton>
      ),
      range: () => ({
        startDate: defineds.startOfCustom,
        endDate: defineds.endOfCustom,
      }),
    },
  ]);
