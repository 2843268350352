import styled from "styled-components";

export const SaleContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 100px;

  .text {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #494949;
  }

  .phonNumbersContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 3% 0 2%;
  }

  .phonNumbers {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #494949;
    display: flex;
    align-items: center;
    svg {
      margin-right: 10px;
    }
  }

  .messengersIcon svg {
    width: 33px;
    height: 33px;
    margin-right: 15px;
  }

  @media (max-width: 1367px) {
    position: absolute;
    top: 85px;
  }

  @media (max-width: 768px) {
    position: absolute;
    top: 150px;
  }
`;

export const ImageContainer = styled.img`
  width: 318px;
  height: 305px;
  margin-bottom: 2%;
`;

export const SaleWrapper = styled.div`
  .footerContainer {
    position: absolute;
    width: 100%;
    bottom: 0;
  }
`;
