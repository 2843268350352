import React from "react";
import NotFoundImage from "../../assets/icon/NotFound.png";

import { ReactComponent as TelegramIcon } from "../../assets/icon/TelegramIcon.svg";
import { ReactComponent as EmailIcon } from "../../assets/icon/EmailIcon.svg";
import { ReactComponent as ViberIcon } from "../../assets/icon/ViberIcon.svg";
import { ReactComponent as WatsUpIcon } from "../../assets/icon/WatsUpIcon.svg";
import { ReactComponent as PhoneIcon } from "../../assets/icon/PhoneIcon.svg";

import { NotFoundContainer, ImageContainer, NotFoundWrapper } from "./styled";
import Footer from "../Footer";
import { useTranslation } from "react-i18next";

const NotFound = () => {
  const { t } = useTranslation();
  return (
    <NotFoundWrapper>
      <NotFoundContainer>
        <ImageContainer src={NotFoundImage}></ImageContainer>
        <div className="notFound">{t("not_found.fot_found")}</div>
        <div className="text">{t("not_found.contacts_specialists")}</div>
        <div className="phonNumbersContainer">
          <div className="phonNumbers">
            <PhoneIcon />
            +374 98 752072
          </div>
          <div className="phonNumbers">
            <PhoneIcon />
            +374 41 444 282
          </div>
          <div className="phonNumbers">
            <PhoneIcon />
            +374 55 012 102
          </div>
        </div>
        <div className="messengersIcon">
          <a
            href="https://api.whatsapp.com/send/?phone=+37441444282"
            data-action="share/whatsapp/share"
            target="_blank"
            rel="noreferrer"
          >
            <WatsUpIcon />
          </a>
          <a
            href="viber://chat?number=%2B37441444282"
            target="_blank"
            rel="noreferrer"
          >
            <ViberIcon />
          </a>
          <a
            href="https://telegram.me/+37441444282"
            target="_blank"
            rel="noreferrer"
          >
            <TelegramIcon />
          </a>
          <a href="mailto:daily@mystreet.am">
            <EmailIcon />
          </a>
        </div>
        <div className="footerContainer">
          <Footer />
        </div>
      </NotFoundContainer>
    </NotFoundWrapper>
  );
};

export default NotFound;
