import styled from "styled-components";

export const BookingSuccessfulContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 10% 0;

  .text {
    width: 45%;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #494949;
  }

  .phonNumbersContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 3% 0 2%;
  }

  .phonNumbers {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #494949;
    display: flex;
    align-items: center;
    svg {
      margin-right: 10px;
    }
  }
  .messengersIcon svg {
    width: 33px;
    height: 33px;
    margin-right: 15px;
  }

`;

export const ImageContainer = styled.img`
  width: 110px;
  height: 84px;
  margin-bottom: 2%;
`;
