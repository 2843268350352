import styled from "styled-components";

export const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #494949;
  background-color: #ffffff;
  padding: 20px 0px 10px 0px;
  border-top: 1px solid #b9b9b9;
  width: 100%;
  height: 168px;
  position: absolute;

  .phoneContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 10px 0px;
    svg {
      margin-right: 10px;
      width: 20px;
      height: 20px;
    }
    div {
      margin-right: 10px;
    }
  }

  .email {
    a {
      color: #494949;
      display: flex;
      align-items: center;
    }
    svg {
      margin-right: 10px;
    }
  }

  .logo {
    width: 100px;
    height: 70px;
  }
`;
