export default {
  mapStyle: [
    {
      featureType: "water",
      elementType: "geometry",
      stylers: [
        {
          color: "#FFFFFF",
        },
        {
          lightness: 17,
        },
      ],
    },
    {
      featureType: "landscape",
      elementType: "geometry",
      stylers: [
        {
          color: "#86A9C8",
        },
        {
          lightness: 20,
        },
      ],
    },
  ],
};
