import styled from "styled-components";

export const ApartmentCardContainer = styled.div`
  display: flex;
  flex-direction: row;
  border: 1px solid #7c8083;
  justify-content: space-between;
  background: #ffffff;
  padding: 16px;
  width: 55%;
  margin-bottom: 16px;

  @media (max-width: 1800px) {
    width: 60%;
  }

  @media (max-width: 1600px) {
    width: 75%;
  }

  @media (max-width: 1366px) {
    width: 80%;
  }

  @media (max-width: 1250px) {
    width: 90%;
  }

  @media (max-width: 1024px) {
    width: 100%;
  }

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    width: 85%;
  }

  @media (max-width: 430px) {
    display: flex;
    flex-direction: column;
    width: 100%;
    border: none;
  }
`;
