import styled from "styled-components";

export const FilterContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100%;
  top: 80px;
  gap: 20px;
  z-index: 10;
  background-color: #f9fafa;
  padding: 32px 0px;
  box-shadow: 0px 4px 4px rgba(42, 85, 120, 0.1);

  .selectCountry {
    width: 15%;
    .ant-select-selector {
      height: 45px;
      display: flex;
      align-items: center;
    }
  }

  .ant-select-auto-complete {
    width: 20%;
  }

  @media (max-width: 1024px) {
    .ant-select-auto-complete {
      width: 30%;
    }
  }

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    padding: 32px 0px;
    position: relative;
    top: 135px;

    .selectCountry {
      width: 39%;
      margin-right: 0px;
    }

    .ant-select-auto-complete {
      width: 39%;
      margin-top: 16px;
    }
  }
  @media (max-width: 430px) {
    .ant-select-auto-complete {
      width: 71%;
    }

    .selectCountry {
      width: 71%;
    }
  }

  @media (max-width: 375px) {
    .ant-select-auto-complete {
      width: 80%;
    }
    .selectCountry {
      width: 80%;
    }
  }

  @media (max-width: 320px) {
    .ant-select-auto-complete {
      width: 89%;
    }
    .selectCountry {
      width: 89%;
    }
  }
`;

export const ModalsButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;

  @media (max-width: 430px) {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
`;

export const CustomButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #ffffff;
  border: 1px solid #7c8083;
  padding: 5px 10px;
  cursor: pointer;
  height: 45px;
  font-size: clamp(0.6rem, 0.9rem, 1rem);
  .topText {
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    color: #7c8083;
  }

  .bottomText {
    font-style: normal;
    font-weight: 500;
    line-height: 15px;
    color: #494949;
  }

  .buttonText {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    gap: 5px
  }

  .iconWrapper {
    display: flex;
  }

  @media (max-width: 320px) {
    width: 100%;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;

  button {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    border-radius: 0px;
  }

  .searchButton {
    background: #3486eb;
    color: #ffffff;
    border: 0.5px solid #3486eb;
    height: 45px;
  }

  .mapButton {
    color: #3486eb;
    border: 0.5px solid #3486eb;
    height: 45px;
  }
  @media (max-width: 320px) {
    .searchButton {
      margin: 0px ;
    }
  }
`;

export const AutoCompleteOption = styled.div`
  display: flex;
  align-items: center;
  span {
    margin-right: 10px;
  }
`;
