import React from "react";
import { Skeleton } from "antd";

import { ReactComponent as LocationIcon } from "../../assets/icon/LocationIcon.svg";
import { ReactComponent as AreaIcon } from "../../assets/icon/AreaIcon.svg";
import { ReactComponent as MaxPersonIcon } from "../../assets/icon/MaxPersonIcon.svg";
import { ReactComponent as RightIcon } from "../../assets/icon/RightIcon.svg";
import { ReactComponent as LeftIcon } from "../../assets/icon/LeftIcon.svg";

import {
  ApartmentListContainer,
  CardContainer,
  Image,
  CustomButton,
  SelectionContainer,
} from "./styled";
import { useNavigate } from "react-router-dom";

const ApartmentList = ({
  setCurrent,
  current,
  houses,
  apartmentCount,
  totalItems,
  loadingData,
}) => {
  const navigate = useNavigate();
  const onPrevDataClick = () => {
    if (current > 1) {
      setCurrent(current - 1);
    }
  };

  const onNextDataClick = () => {
    if (current < totalItems / apartmentCount) {
      setCurrent(current + 1);
    }
  };

  return (
    <ApartmentListContainer>
      <CustomButton onClick={onPrevDataClick} disabled={current === 1}>
        <LeftIcon />
      </CustomButton>
      {loadingData
        ? Array(apartmentCount)
            .fill()
            .map((x, i) => i)
            .map((i) => (
              <SelectionContainer key={i}>
                <Skeleton.Image active={true} />
                <Skeleton active paragraph={{ rows: 2 }} />
              </SelectionContainer>
            ))
        : houses?.getAllHomesInfo?.data.map((item) => (
            <CardContainer
              key={item.id}
              onClick={() => {
                window.scrollTo({
                  top: 0,
                  left: 0,
                  behavior: "smooth",
                });
                navigate(`/apartment-detail/${item.id}`,  {
                  state: {
                    estimatedCost: item.estimatedCost,
                    pricePerNight: item.pricePerNight,
                    notDiscountPrice: item.notDiscountPrice
                  },
                } );
              }}
            >
              <Image url={item.images[0].path}></Image>
              <div className="title">
                {item.title?.length > 30
                  ? item.title?.substring(
                      0,
                      item.title?.length - (item.title?.length - 30)
                    ) + "..."
                  : item.title}
              </div>
              <div className="location">
                <LocationIcon /> <div>{item?.internal_location}</div>
              </div>
              <div className="info">
                <div className="maxPerson">
                  <MaxPersonIcon /> {item.max_persons}
                </div>
                <div>
                  <AreaIcon /> {item.area} m²
                </div>
              </div>
            </CardContainer>
          ))}
      <CustomButton
        onClick={onNextDataClick}
        disabled={current >= totalItems / apartmentCount}
      >
        <RightIcon />
      </CustomButton>
    </ApartmentListContainer>
  );
};

export default ApartmentList;
