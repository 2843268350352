import React, { useEffect, useState } from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { dateFormat } from "../../utils/constants";
import { addDays, endOfDay } from "date-fns";
import { Button } from "antd";
import GoogleMap from "../GoogleMap";
import { ReactComponent as CalendarIcon } from "../../assets/icon/CalendarIcon.svg";
import { ReactComponent as LocationIcon } from "../../assets/icon/LocationIcon.svg";

import {
  RightContainer,
  TermsLeaseContainer,
  AddressContainer,
  GoogleMapContainer,
} from "./styled";
import { useTranslation } from "react-i18next";

const ApartmentCardRight = ({
  setViewBooking,
  apartmentData,
  personsCount,
  dataState,
  state,
}) => {
  const [width, setWidth] = useState(window.innerWidth);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dateRange = Math.ceil(
    (new Date(dataState[0]?.endDate) - new Date(dataState[0]?.startDate)) /
      (1000 * 3600 * 24)
  );

  const { estimatedCost, notDiscountPrice, pricePerNight } = state = {};

  const onOpenBookedClick = () => {
    setViewBooking(true);
    navigate("/booking-apartment/:id".replace(":id", apartmentData.id), {
      state: {
        dataState: dataState,
        personsCount: personsCount,
        totaledPriceState: {
          estimatedCost: apartmentData?.estimatedCost,
          pricePerNight: apartmentData?.pricePerNight,
        },
        dateRange,
      },
    });
  };

  const updateWindowDimensions = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", updateWindowDimensions);
  }, [width]);

  const updateMapSize = () => {
    if (width >= 1600) {
      return { width: "21%", height: "450px" };
    } else if (width >= 1400) {
      return { width: "29%", height: "450px" };
    } else if (width >= 1100) {
      return { width: "30%", height: "450px" };
    } else if (width >= 1024) {
      return { width: "37%", height: "450px" };
    } else if (width >= 800) {
      return { width: "37%", height: "450px" };
    } else if (width >= 768) {
      return { width: "37%", height: "224px" };
    } else if (width >= 425) {
      return { width: "80%", height: "224px" };
    } else if (width >= 375) {
      return { width: "93%", height: "224px" };
    } else if (width >= 320) {
      return { width: "85%", height: "224px" };
    }
  };

  return (
    <RightContainer>
      <TermsLeaseContainer
        margin={apartmentData?.propertyes[0]?.prepayment ? "20px" : "0px"}
      >
        {personsCount.adults > 1 ||
        personsCount.children ||
        personsCount.infant ? (
          <div className="personsCount">{`${personsCount.adults} adult, ${personsCount.children} child, ${personsCount.infant} infant - ${dateRange} night`}</div>
        ) : null}

        {moment(endOfDay(addDays(new Date(), +1))).format("YYYY-MM-DD") ===
        moment(dataState[0].endDate).format("YYYY-MM-DD") ? null : (
          <>
            <div className="freeDays">
              <CalendarIcon />
              {`${moment(dataState[0].startDate).format(dateFormat)} - ${moment(
                dataState[0].endDate
              ).format(dateFormat)}`}
            </div>
            {apartmentData?.pricePerNight || pricePerNight ? (
              <div className="nightPrice">
                <div className="price">{`$ ${
                  apartmentData?.pricePerNight ?? pricePerNight
                }`}</div>
                /{t("apartment_card.night")}
              </div>
            ) : null}
            <div className="totalAmount">
              {apartmentData?.notDiscountPrice ||
                (notDiscountPrice > 0 && (
                  <div className="totalPrice">
                    {`$ ${
                      apartmentData.notDiscountPrice ?? notDiscountPrice
                    }/ ${t("apartment_card.total")}`}
                    <div className="line"></div>
                  </div>
                ))}
              {apartmentData?.estimatedCost || estimatedCost ? (
                <div className="salePrice">{`$ ${
                  apartmentData?.estimatedCost ?? estimatedCost
                }/  ${t("apartment_card.total")}`}</div>
              ) : null}
            </div>
          </>
        )}

        <div className="buttonsContainer">
          <Button className="viewDeal" onClick={onOpenBookedClick}>
            {t("apartment_card.send_request")}
          </Button>
        </div>
      </TermsLeaseContainer>
      <AddressContainer>
        <LocationIcon />
        {apartmentData?.internal_location}
      </AddressContainer>
      <GoogleMapContainer>
        <GoogleMap containerStyle={updateMapSize()} house={apartmentData} />
      </GoogleMapContainer>
    </RightContainer>
  );
};

export default ApartmentCardRight;
