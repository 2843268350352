import React, { useRef } from "react";
import { Button, Carousel, Popover } from "antd";
import moment from "moment";
import { dateFormat } from "../../utils/constants";
import { addDays, endOfDay } from "date-fns";

import { ReactComponent as RightIcon } from "../../assets/icon/RightIcon.svg";
import { ReactComponent as LocationIcon } from "../../assets/icon/LocationIcon.svg";
import { ReactComponent as BedroomIcon } from "../../assets/icon/BedroomIcon.svg";
import { ReactComponent as MaxPersonIcon } from "../../assets/icon/MaxPersonIcon.svg";
import { ReactComponent as AreaIcon } from "../../assets/icon/AreaIcon.svg";
import { ReactComponent as ElevatorIcon } from "../../assets/icon/ElevatorIcon.svg";
import { ReactComponent as BedIcon } from "../../assets/icon/BedIcon.svg";
import { ReactComponent as WifiIcon } from "../../assets/icon/WifiIcon.svg";
import { ReactComponent as IronIcon } from "../../assets/icon/IronIcon.svg";
import { ReactComponent as SwimmingPoolIcon } from "../../assets/icon/SwimmingPoolIcon.svg";
import { ReactComponent as CalendarIcon } from "../../assets/icon/CalendarIcon.svg";
import { ReactComponent as NextImageIcon } from "../../assets/icon/NextImageIcon.svg";
import { ReactComponent as PrevImageIcon } from "../../assets/icon/PrevImageIcon.svg";

import {
  CardContainer,
  ImagContainer,
  InfosContainer,
  SaleContainer,
  TermsLeaseContainer,
  CustomButton,
  AllServicesButton,
  ServicesTextContainer,
  Title,
  Imag,
  Row1,
  Row2,
  Row3,
  Row4,
} from "./styled";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Card = ({
  viewMap,
  house,
  setViewMap,
  personsCount,
  dataState,
  width,
  setCurrent,
}) => {
  const { t } = useTranslation();
  const carousel = useRef();
  const navigate = useNavigate();
  const dateRange = Math.ceil(
    (new Date(dataState[0].endDate) - new Date(dataState[0].startDate)) /
      (1000 * 3600 * 24)
  );

  const dotsSize =
    house?.images?.length > 15 && house?.images?.length <= 25
      ? "4px"
      : house?.images?.length > 25 && house?.images?.length <= 35
      ? "2px"
      : house?.images?.length > 35
      ? "1px"
      : "8px";

  const onPrevImageClick = () => {
    carousel.current.prev();
  };

  const onNextImageClick = () => {
    carousel.current.next();
  };

  const onViewDealClick = () => {
    setViewMap(false);
    setCurrent(1);
    navigate(`/apartment-detail/${house.id}`, {
      state: {
        estimatedCost: house.estimatedCost,
        pricePerNight: house.pricePerNight,
        notDiscountPrice: house.notDiscountPrice
      },
    });
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  const onOrderNow = () => {
    setViewMap(false);
    if (house?.id) {
      navigate("/booking-apartment/:id".replace(":id", house?.id), {
        state: {
          dataState: dataState,
          personsCount: personsCount,
          totaledPriceState: {
            estimatedCost: house?.estimatedCost,
            pricePerNight: house?.pricePerNight,
          },
          dateRange,
        },
      });
    }
  };

  const onDeleteServiced = () => {
    return house?.propertyes[0].services?.map((services) => {
      switch (services) {
        case "Bad":
          return <BedIcon key={services} />;
        case "Wi-Fi":
          return <WifiIcon key={services} />;
        case "Iron":
          return <IronIcon key={services} />;
        case "Washing machine":
          return <SwimmingPoolIcon key={services} />;
        default:
          return;
      }
    });
  };

  const filteredArr = () => {
    return house?.propertyes[0].services?.filter(
      (item) =>
        item !== "Bad" &&
        item !== "Wi-Fi" &&
        item !== "Iron" &&
        item !== "Washing machine"
    );
  };

  const content = (
    <ServicesTextContainer>
      {filteredArr()?.map((item) => (
        <div className="servicesText" key={item}>
          {item}
        </div>
      ))}
    </ServicesTextContainer>
  );

  return (
    <CardContainer
      width={viewMap ? "50%" : "60%"}
      width1470={viewMap ? "60%" : "80%"}
      width1100={viewMap ? "60%" : "95%"}
    >
      <ImagContainer width={dotsSize} height={dotsSize}>
        <Carousel autoplay speed={1500} ref={carousel}>
          {house?.images?.map((im) => (
            <div key={im.id}>
              <Imag url={im.path}></Imag>
            </div>
          ))}
        </Carousel>
        <CustomButton className="nextButton " onClick={onNextImageClick}>
          <NextImageIcon />
        </CustomButton>
        <CustomButton className="prevButton" onClick={onPrevImageClick}>
          <PrevImageIcon />
        </CustomButton>
      </ImagContainer>
      <InfosContainer>
        <Title>{house.title}</Title>
        <Row1>
          <LocationIcon />
          <div className="infoText address">{house.internal_location}</div>
        </Row1>
        <Row2 top={viewMap ? "10px" : "0px"}>
          <div className="bedroomContainer">
            <BedroomIcon />
            <div className="infoText">
              {house.bedrooms} {t("apartment_card.bedrooms")}
            </div>
          </div>
          <div className="maxPersonContainer">
            <MaxPersonIcon className="maxPerson" />
            <div className="infoText">
              {t("apartment_card.max")} {house.max_persons}
            </div>
          </div>
        </Row2>
        <Row3
          elevatorTop={
            (viewMap && width <= 1375 && width >= 1024) ||
            (viewMap && width <= 1650 && width >= 1470)
              ? "10px"
              : "0px"
          }
        >
          <div className="area">
            <AreaIcon /> <div className="infoText">{house.area} m ²</div>
          </div>
          <div className="infoText">
            {t("apartment_card.floor")} {house.num_floors}
          </div>
          {!house?.elevator ? (
            <div className="elevatorWrapper">
              <ElevatorIcon />
              <div className="infoText elevator">
                {t("apartment_card.elevator")}
              </div>
              <div className="line"></div>
            </div>
          ) : (
            <div className="elevatorWrapper activeElevator ">
              <ElevatorIcon />
              <div className="infoText activeElevatorText ">
                {t("apartment_card.elevator")}
              </div>
            </div>
          )}
        </Row3>
        <Row4>
          {onDeleteServiced()}
          {filteredArr()?.length > 0 ? (
            <Popover content={content} title="All Services">
              <AllServicesButton>+{filteredArr()?.length}</AllServicesButton>
            </Popover>
          ) : null}
        </Row4>
      </InfosContainer>
      <TermsLeaseContainer
        margin={house?.propertyes[0]?.prepayment ? "20px" : "0px"}
        flex={viewMap ? "column" : "row"}
        margin_top={viewMap ? "10px 0px 0px 0px" : "0px 0px 0px 10px"}
      >
        <div></div>
        {personsCount.adults > 1 ||
        personsCount.children ||
        personsCount.infant ? (
          <div className="personsCount">{`${personsCount.adults} adult ${personsCount.children} child ${personsCount.infant} infant ${dateRange} night`}</div>
        ) : null}
        {moment(endOfDay(addDays(new Date(), +1))).format("YYYY-MM-DD") ===
        moment(dataState[0].endDate).format("YYYY-MM-DD") ? null : (
          <>
            <div className="freeDays">
              <CalendarIcon />
              {`${moment(dataState[0].startDate).format(dateFormat)} - ${moment(
                dataState[0].endDate
              ).format(dateFormat)}`}
            </div>
            {house?.pricePerNight ? (
              <div className="nightPrice">
                <div className="price">{`$ ${Math.ceil(
                  house?.pricePerNight
                )}`}</div>
                /{t("apartment_card.night")}
              </div>
            ) : null}
            <div className="totalAmount">
              {house.totalDiscount > 0 && (
                <div className="totalPrice">
                  {`$ ${house.notDiscountPrice}/ ${t("apartment_card.total")}`}
                  <div className="line"></div>
                </div>
              )}
              {house?.estimatedCost ? (
                <div className="salePrice">{`$ ${Math.ceil(
                  house?.estimatedCost
                )}/  ${t("apartment_card.total")}`}</div>
              ) : null}
            </div>
          </>
        )}

        <div className="buttonsContainer">
          <Button className="orderNow" onClick={onOrderNow}>
            {t("apartment_card.send_request_button")}
          </Button>
          <Button className="viewDeal" onClick={onViewDealClick}>
            {t("apartment_card.view—deal_button")} <RightIcon />
          </Button>
        </div>
      </TermsLeaseContainer>
      {house.discount > 0 || house.totalDiscount > 0 ? (
        <SaleContainer>
          <div>{house.discount || house.totalDiscount}%</div>
          <div>{t("apartment_card.sale")}</div>
        </SaleContainer>
      ) : null}
    </CardContainer>
  );
};

export default Card;
