import React, { useEffect, useState } from "react";
import Filter from "../../components/Filter";
import Card from "../../components/Card";
import ViewApartmentDetails from "../../components/ViewApartmentDetails";
import Footer from "../../components/Footer";
import GoogleMap from "../../components/GoogleMap";
import SkeletonsComponent from "../../components/Skeleton";
import NotFound from "../../components/NotFound";
import moment from "moment";
import { filterDateFormatRequest } from "../../utils/constants";
import { addDays, endOfDay, startOfDay } from "date-fns";
import { useLazyQuery, useQuery } from "@apollo/client";
import {
  GET_ALL_APARTAMENT_INFO,
  FILTER_APARTMENT_BY_FILLES,
} from "../../components/Graphql/query";
import { Pagination } from "antd";

import {
  DailyRentContainer,
  FilterContainer,
  MainContainer,
  CardsContainer,
  BottomContainer,
} from "./styled";
import { useLocation, useParams } from "react-router-dom";

const DailyRent = () => {
  const { id } = useParams();
  const [viewMap, setViewMap] = useState(false);
  const [houses, setHouses] = useState([]);
  const [width, setWidth] = useState(window.innerWidth);
  const [current, setCurrent] = useState(1);
  const [viewBooking, setViewBooking] = useState(false);
  const [searchData, setSearchData] = useState([]);
  const [selectedSearchValue, setSelectedSearchValue] = useState("");
  const [selectValue, setSelectValue] = useState("");
  const [apartmentCount, setApartmentCount] = useState(4);
  const [personsCount, setPersonsCount] = useState({
    adults: 1,
    children: 0,
    infant: 0,
    bedrooms: 0,
  });
  const [dataState, setDataState] = useState([
    {
      startDate: startOfDay(new Date()),
      endDate: endOfDay(addDays(new Date(), +1)),
      key: "selection",
    },
  ]);

  const { pathname, state } = useLocation();

  const comparDate =
    moment(endOfDay(addDays(new Date(), +1))).format("YYYY-MM-DD") !==
    moment(dataState[0]?.endDate).format("YYYY-MM-DD");

  const { data, loading } = useQuery(GET_ALL_APARTAMENT_INFO, {
    variables: {
      status: ["active", "booked"],
      page: current,
      size: apartmentCount,
    },
  });
  const [
    getFilteredApartment,
    { data: filteredData, loading: filteredLoading, refetch },
  ] = useLazyQuery(FILTER_APARTMENT_BY_FILLES, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
    variables: {
      page: current,
      size: apartmentCount,
    },
  });

  const updateWindowDimensions = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    if (!loading && data) {
      setHouses(data);
    }
  }, [data, loading]);

  useEffect(() => {
    if (
      (selectedSearchValue || selectValue || filteredData) &&
      filteredData &&
      !filteredLoading
    ) {
      setHouses(filteredData);
    }
  }, [
    data,
    filteredData,
    filteredLoading,
    loading,
    selectValue,
    selectedSearchValue,
    current,
  ]);

  useEffect(() => {
    if (!loading && houses && searchData.length === 0) {
      setSearchData([
        ...data?.getAllHomesInfo?.filteredData?.erevan,
        ...data?.getAllHomesInfo?.filteredData?.tsaghkadzor,
      ]);
    }
  }, [data]);

  useEffect(() => {
    window.addEventListener("resize", updateWindowDimensions);
  }, [width]);

  useEffect(() => {
    setCurrent(1);
    setSelectedSearchValue("");
    setSelectValue("");
  }, []);

  const onPaginationChange = (page) => {
    setCurrent(page);
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });

    if (
      comparDate ||
      personsCount.adults > 1 ||
      personsCount.children ||
      personsCount.infant ||
      selectedSearchValue
    ) {
      getFilteredApartment({
        variables: {
          filterInput: {
            search: selectedSearchValue,
            filter_city: selectValue,
            start_filter_date: comparDate
              ? moment(dataState[0]?.startDate).format(filterDateFormatRequest)
              : null,
            end_filter_date: comparDate
              ? moment(dataState[0]?.endDate).format(filterDateFormatRequest)
              : null,
            ...personsCount,
          },
        },
      });
    }
  };

  const updateMapSize = () => {
    if (width >= 1800) {
      return { width: "49%", height: "calc(100% - 123px)", right: 0, top: 0 };
    } else if (width >= 1700) {
      return { width: "49%", height: "calc(100% - 123px)", right: 0, top: 0 };
    } else if (width >= 1470) {
      return { width: "49%", height: "calc(100% - 123px)", right: 0, top: 0 };
    } else if (width > 1024) {
      return { width: "39%", height: "calc(100% - 123px)", right: 0, top: 0 };
    } else if (width >= 768) {
      return { width: "100%", height: "600px", left: 0, top: 0 };
    } else if (width >= 425) {
      return { width: "100%", height: "600px", right: 0, top: 50 };
    } else if (width >= 375) {
      return { width: "100%", height: "600px", right: 0, top: 0 };
    } else if (width >= 320) {
      return { width: "100%", height: "600px", right: 0, top: 0 };
    } else {
      return { width: "100%", height: "600px", right: 0, top: 0 };
    }
  };

  return (
    <>
      {data?.getAllHomesInfo?.data?.length === 0 ? (
        <NotFound />
      ) : (
        <DailyRentContainer>
          {!viewBooking ? (
            <FilterContainer>
              <Filter
                viewMap={viewMap}
                setViewMap={setViewMap}
                searchData={searchData}
                setSearchData={setSearchData}
                houses={data}
                setSelectedSearchValue={setSelectedSearchValue}
                setSelectValue={setSelectValue}
                getFilteredApartment={getFilteredApartment}
                filteredLoading={loading}
                personsCount={personsCount}
                setPersonsCount={setPersonsCount}
                dataState={dataState}
                setDataState={setDataState}
                setCurrent={setCurrent}
                selectValue={selectValue}
                refetch={refetch}
                selectedSearchValue={selectedSearchValue}
                current={current}
                apartmentCount={apartmentCount}
              />
            </FilterContainer>
          ) : null}

          <MainContainer
            align={viewMap ? "flex-start" : "center"}
            top={viewMap && width <= 1024 ? "650px" : "0px"}
            topMain={!viewBooking ? "175px" : "80px "}
          >
            {viewMap && width <= 1024 ? (
              <GoogleMap houses={houses} containerStyle={updateMapSize()} />
            ) : null}
            <div className="contentWrapper">
              {viewMap && width > 1024 ? (
                <GoogleMap houses={houses} containerStyle={updateMapSize()} />
              ) : null}
              <>
                {id ? (
                  <ViewApartmentDetails
                    width={width}
                    totalItems={houses}
                    filteredListData={filteredData}
                    filteredLoadingData={filteredLoading}
                    personsCount={personsCount}
                    dataState={dataState}
                    apartmentCount={apartmentCount}
                    setApartmentCount={setApartmentCount}
                    current={current}
                    setCurrent={setCurrent}
                    setViewBooking={setViewBooking}
                    state={state}
                  />
                ) : (
                  <div className="cardWrapper">
                    {loading ? (
                      <SkeletonsComponent width={viewMap ? "50%" : "60%"} />
                    ) : (
                      <CardsContainer
                        align={
                          viewMap && width >= 1024 ? "flex-start" : "center"
                        }
                        width={viewMap ? "50%" : "60%"}
                      >
                        {houses?.getAllHomesInfo?.data?.map((item) => (
                          <Card
                            viewMap={viewMap}
                            key={item.id}
                            house={item}
                            setViewMap={setViewMap}
                            personsCount={personsCount}
                            dataState={dataState}
                            width={width}
                            setCurrent={setCurrent}
                          />
                        ))}
                      </CardsContainer>
                    )}
                  </div>
                )}
                {houses?.getAllHomesInfo?.totalItems > 4 && pathname === "/" ? (
                  <Pagination
                    current={current}
                    onChange={onPaginationChange}
                    defaultPageSize={4}
                    total={houses?.getAllHomesInfo?.totalItems}
                    showTitle={false}
                  />
                ) : null}
              </>
              <BottomContainer>
                <Footer />
              </BottomContainer>
            </div>
          </MainContainer>
        </DailyRentContainer>
      )}
    </>
  );
};

export default DailyRent;
