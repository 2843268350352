import React, { useState } from "react";
import { Map, Marker, InfoWindow, GoogleApiWrapper } from "google-maps-react";
import { Wrapper } from "./styled";
import MapsStyle from "./MapsStyle";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

const GoogleMap = ({ google, containerStyle, houses, house }) => {
  const { t } = useTranslation();
  const [initialCenter, setInitialCenter] = useState({
    lat: 40.187381122813576,
    lng: 44.51654252606394,
  });

  const [info, setInfo] = useState({
    selectedPlace: "",
    activeMarker: {},
    showInfoWindow: false,
    lat: "",
    lng: "",
  });

  useEffect(() => {
    if (!!house?.location.lat) {
      setInitialCenter({ lat: house?.location.lat, lng: house?.location.lng });
    }
  }, [house?.location.lat, house?.location.lng]);

  const onMarkerClick = (props, marker, e, house) => {
    if (house.location.lat !== info.loc && house.location.lng !== info.lng) {
      setInfo({
        selectedPlace: props,
        activeMarker: marker,
        showInfoWindow: true,
        lat: marker.position.lat(),
        lng: marker.position.lng(),
        img: house?.images[0].path,
        room: house?.bedrooms,
        persons: house?.max_persons,
      });
    }
  };

  return (
    <Wrapper>
      <Map
        containerStyle={containerStyle}
        styles={MapsStyle.mapStyle}
        resetBoundsOnResize={true}
        google={google}
        zoom={13}
        streetViewControl={false}
        fullscreenControl={true}
        center={initialCenter}
        initialCenter={initialCenter}
      >
        {houses ? (
          houses?.getAllHomesInfo?.data?.map((house, i) => (
            <Marker
              key={i}
              name={house.location.address}
              onMouseover={(props, marker, e) =>
                onMarkerClick(props, marker, e, house)
              }
              position={{ lat: house.location.lat, lng: house.location.lng }}
            />
          ))
        ) : (
          <Marker
            key={house?.id}
            name={house?.location.address}
            position={{ lat: house?.location.lat, lng: house?.location.lng }}
          />
        )}

        <InfoWindow marker={info?.activeMarker} visible={info?.showInfoWindow}>
          <div className="map_info">
            <h2>{info?.selectedPlace.name}</h2>
            <div className="map_img">
              <img src={info?.img} alt={info.selectedPlace} />
            </div>
            <p>
              {t("google_map.bedrooms")}: {info?.room}
            </p>
            <p>
              {t("google_map.max_guests")}: {info?.persons}
            </p>
          </div>
        </InfoWindow>
      </Map>
    </Wrapper>
  );
};

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
})(GoogleMap);
