import React, { useEffect, useState } from "react";
import { rout } from "./rout";
import { ReactComponent as MyStreetSvg } from "../../assets/icon/MyStreetSvg.svg";
import { ReactComponent as BurgerIcon } from "../../assets/icon/BurgerIcon.svg";
import { Popover, Select } from "antd";
import { Link, useLocation, useNavigate } from "react-router-dom";

import {
  HeaderContainer,
  RoutContainer,
  LanguageContainer,
  CustomButton,
  LinksPopover,
} from "./style";
import { useTranslation } from "react-i18next";

const { Option } = Select;

const Header = () => {
  const [openPopover, setOpenPopover] = useState(false);
  const [language, setLanguage] = useState("en");
  const { t, i18n } = useTranslation();

  const navigate = useNavigate();

  const location = useLocation();

  const handleLanguageChange = (value) => {
    setLanguage(value);
    localStorage.setItem("language", value);
    i18n.changeLanguage(value);
  };

  useEffect(() => {
    const localLanguage = localStorage.getItem("language");
    i18n.changeLanguage(localLanguage === null ? language : localLanguage);
    setLanguage(localLanguage);
  }, [language]);

  const onNavigateHome = () => {
    navigate("/");
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  const handleOpenChange = () => {
    setOpenPopover(!openPopover);
  };

  const content = (
    <LinksPopover>
      {rout(t).map((item) => (
        <div onClick={() => setOpenPopover(false)} key={item.name}>
          <Link to={item.pathName}>
            {item.icon} {item.name}
          </Link>
        </div>
      ))}
    </LinksPopover>
  );

  return (
    <HeaderContainer
      padding={
        language === "am" ? "15px 30px 15px 30px" : "15px 130px 15px 120px"
      }
    >
      <CustomButton onClick={onNavigateHome}>
        <MyStreetSvg />
      </CustomButton>
      <RoutContainer
        left={
          language === "en" ? "160px" : language === "ru" ? "150px" : "30px"
        }
      >
        {rout(t).map((item) => (
          <div
            key={item.name}
            className={location.pathname === item.pathName ? "active" : ""}
          >
            <Link to={item.pathName}>
              {item.icon}
              <span className={item.class}>{item.name}</span>
            </Link>
          </div>
        ))}
      </RoutContainer>
      <LanguageContainer>
        <Select
          defaultValue={
            localStorage.getItem("language") === null
              ? language
              : localStorage.getItem("language")
          }
          style={{
            width: 80,
          }}
          bordered={false}
          onChange={handleLanguageChange}
          getPopupContainer={(trigger) => trigger.parentNode}
        >
          <Option value="en">ENG</Option>
          <Option value="am">ՀԱՅ</Option>
          <Option value="ru">РУС</Option>
        </Select>
      </LanguageContainer>

      <Popover
        placement="bottomRight"
        content={content}
        trigger="click"
        open={openPopover}
        onOpenChange={handleOpenChange}
        getPopupContainer={(trigger) => trigger.parentNode}
      >
        <BurgerIcon className="burgerIcon" />
      </Popover>
    </HeaderContainer>
  );
};

export default Header;
