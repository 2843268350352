import styled from "styled-components";

export const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 40%;

  @media (max-width: 1024px) {
   width: 38%;
  }

  @media (max-width: 768px) {
    width: 100%;
    margin-top: 20px;
  }
`;

export const TermsLeaseContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  background-color: #f9f9f9;
  width: 100%;
  padding: 13px 8px 6px 8px;

  .buttonsContainer {
    display: flex;
    flex-direction: row;
    button {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      color: #ffffff;
    }

    .viewDeal {
      background-color: #3486eb;
      display: flex;
      align-items: center;
      margin-left: 13px;
      border: 1px solid #3486eb;
      color: #ffffff;
      margin-top: 10px;
    }
  }

  .myStreet {
    font-size: 12px;
    line-height: 14px;
    color: #494949;
    margin-bottom: 10px;
  }

  .personsCount {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #494949;
    margin: 10px 0px;
  }

  .freeDays {
    display: flex;
    align-items: center;
    font-size: 12px;
    line-height: 14px;
    color: #3486eb;

    svg {
      margin-right: 7px;
      path {
        fill: #3486eb;
      }
    }
  }
  .nightPrice {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    font-size: 10px;
    line-height: 12px;
    color: #3e64ff;
    margin: 10px 0px;
    .price {
      font-weight: 900;
      font-size: 16px;
      line-height: 19px;
    }
  }

  .totalAmount {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: 700;
    font-size: 16px;
    color: #494949
  }

  /* .salePrice {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #494949;
  } */

  .totalPrice {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #494949;
    margin-right: ${(props) => `${props.margin}`};;
    position: relative;

    .line {
      position: absolute;
      top: 10px;
      width: 90px;
      border-top: 2px solid #ff6433;
      border-radius: 5px;
    }
  }
`;

export const AddressContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 17px 0px 9px;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #7c8083;

  svg {
    margin-right: 20px;
  }
`;
export const GoogleMapContainer = styled.div`
  width: 100%;
  height: 224px;
`;
