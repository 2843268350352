import React from "react";

import { ReactComponent as MyStreetSvg } from "../../assets/icon/MyStreetSvg.svg";
import { ReactComponent as EmailIcon } from "../../assets/icon/EmailIcon.svg";
import { ReactComponent as PhoneIcon } from "../../assets/icon/PhoneIcon.svg";
import { ReactComponent as ViberIcon } from "../../assets/icon/ViberIcon.svg";
import { ReactComponent as WatsUpIcon } from "../../assets/icon/WatsUpIcon.svg";
import { ReactComponent as TelegramIcon } from "../../assets/icon/TelegramIcon.svg";

import { FooterContainer } from "./styled";

const Footer = () => {
  return (
    <FooterContainer>
      <div>
        {" "}
        <MyStreetSvg className="logo" />
      </div>
      <div className="phoneContainer">
        <PhoneIcon /> <div>+374 55 012102</div>
        <a
          href="https://api.whatsapp.com/send/?phone=+37441444282"
          target="_blank"
          rel="noreferrer"
          data-action="share/whatsapp/share"
        >
          <WatsUpIcon />
        </a>
        <a
          href="viber://chat?number=%2B37441444282"
          target="_blank"
          rel="noreferrer"
        >
          <ViberIcon />
        </a>
        <a
          href="https://telegram.me/+37441444282"
          target="_blank"
          rel="noreferrer"
        >
          <TelegramIcon />
        </a>
      </div>
      <div className="email">
        <a href="mailto:daily@mystreet.am">
          <EmailIcon /> daily@mystreet.am
        </a>
      </div>
    </FooterContainer>
  );
};

export default Footer;
