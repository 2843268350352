import React from "react";
import { Button, InputNumber, Modal, Select } from "antd";
import { options } from "./options";
import moment from "moment";

import { ReactComponent as MinusIcon } from "../../assets/icon/MinusIcon.svg";
import { ReactComponent as PluseIcon } from "../../assets/icon/PluseIcon.svg";

import {
  PersonModalContainer,
  PersonModalContent,
  ModalItemsContainer,
  ChildrenAgeContainer,
  SelectsContainer,
} from "./styled";
import { useTranslation } from "react-i18next";
import { filterDateFormatRequest } from "../../utils/constants";
import { endOfDay } from "date-fns";
import { addDays } from "date-fns";

const { Option } = Select;

const PersonCountModal = ({
  isPersonCountModalOpen,
  setIsPersonCountModalOpen,
  personsCount,
  setPersonsCount,
  getFilteredApartment,
  selectedSearchValue,
  current,
  apartmentCount,
  dataState,
  selectValue,
}) => {
  const { t } = useTranslation();
  const comparDate =
    moment(endOfDay(addDays(new Date(), +1))).format("YYYY-MM-DD") !==
    moment(dataState[0].endDate).format("YYYY-MM-DD");

  const handleApply = () => {
    setIsPersonCountModalOpen(false);
    getFilteredApartment({
      variables: {
        filterInput: {
          search: selectedSearchValue,
          filter_city: selectValue,
          start_filter_date: comparDate
            ? moment(dataState[0]?.startDate).format(filterDateFormatRequest)
            : null,
          end_filter_date: comparDate
            ? moment(dataState[0]?.endDate).format(filterDateFormatRequest)
            : null,
          ...personsCount,
        },
      },
    });
  };

  const handleCancel = () => {
    setIsPersonCountModalOpen(false);
  };

  const handleReset = () => {
    setPersonsCount({
      adults: 1,
      children: 0,
      infant: 0,
      bedrooms: 0,
    });
    setIsPersonCountModalOpen(false);
  };

  const handleChange = (value) => {
    console.log(`selected ${value}`);
  };

  const onNumberInputChange = (value, name) => {
    setPersonsCount({
      ...personsCount,
      [name]: value,
    });
  };

  const onDecrementClick = (name, value) => {
    if (value >= 1) {
      setPersonsCount({
        ...personsCount,
        [name]: value - 1,
      });
    }
  };

  const onIncrementClick = (name, value) => {
    if (value < 10) {
      setPersonsCount({
        ...personsCount,
        [name]: value + 1,
      });
    }
  };

  return (
    <PersonModalContainer>
      <Modal
        className="personCountModal"
        title={false}
        open={isPersonCountModalOpen}
        footer={false}
        width={315}
        onCancel={handleCancel}
      >
        <PersonModalContent>
          <ModalItemsContainer>
            <div className="text"> {t("filters_persons_count.adults")}</div>
            <div className="buttonsWrapper">
              <button
                type="button"
                className="incrementDecrement"
                onClick={() => onDecrementClick("adults", personsCount.adults)}
              >
                <MinusIcon />
              </button>
              <InputNumber
                value={personsCount.adults}
                onChange={(value) => onNumberInputChange(value, "adults")}
                className="inputNumber"
              />
              <button
                type="button"
                className="incrementDecrement"
                onClick={() => onIncrementClick("adults", personsCount.adults)}
              >
                <PluseIcon />
              </button>
            </div>
          </ModalItemsContainer>
          <ModalItemsContainer>
            <div className="text">
              {" "}
              {t("filters_persons_count.children")} (2-12)
            </div>
            <div className="buttonsWrapper">
              <button
                type="button"
                className="incrementDecrement"
                onClick={() =>
                  onDecrementClick("children", personsCount.children)
                }
              >
                <MinusIcon />
              </button>
              <InputNumber
                defaultValue={3}
                value={personsCount.children}
                onChange={(value) => onNumberInputChange(value, "children")}
              />
              <button
                type="button"
                className="incrementDecrement"
                onClick={() =>
                  onIncrementClick("children", personsCount.children)
                }
              >
                <PluseIcon />
              </button>
            </div>
          </ModalItemsContainer>
          {personsCount.children !== 0 ? (
            <ChildrenAgeContainer>
              <span className="childrenAge ">
                *{t("filters_persons_count.children_age")}{" "}
              </span>
              <SelectsContainer>
                {Array(personsCount.children)
                  .fill()
                  .map((x, i) => i)
                  .map((i) => (
                    <Select
                      key={i}
                      defaultValue="2"
                      style={{ width: 60 }}
                      onChange={handleChange}
                      getPopupContainer={(trigger) => trigger.parentNode}
                    >
                      {options.map((optionItem) => (
                        <Option value={optionItem} key={optionItem}>
                          {optionItem}
                        </Option>
                      ))}
                    </Select>
                  ))}
              </SelectsContainer>
            </ChildrenAgeContainer>
          ) : null}
          <ModalItemsContainer>
            <div className="text">
              {" "}
              {t("filters_persons_count.infants")} (0-2)
            </div>
            <div className="buttonsWrapper">
              <button
                type="button"
                className="incrementDecrement"
                onClick={() => onDecrementClick("infant", personsCount.infant)}
              >
                <MinusIcon />
              </button>
              <InputNumber
                defaultValue={3}
                value={personsCount.infant}
                onChange={(value) => onNumberInputChange(value, "infant")}
              />
              <button
                className="incrementDecrement"
                onClick={() => onIncrementClick("infant", personsCount.infant)}
              >
                <PluseIcon />
              </button>
            </div>
          </ModalItemsContainer>
          <ModalItemsContainer>
            <div className="text">{t("filters_persons_count.bedrooms")}</div>
            <div className="buttonsWrapper">
              <button
                className="incrementDecrement"
                onClick={() =>
                  onDecrementClick("bedrooms", personsCount.bedrooms)
                }
              >
                <MinusIcon />
              </button>
              <InputNumber
                defaultValue={3}
                value={personsCount.bedrooms}
                onChange={(value) => onNumberInputChange(value, "bedrooms")}
              />
              <button
                type="button"
                className="incrementDecrement"
                onClick={() =>
                  onIncrementClick("bedrooms", personsCount.bedrooms)
                }
              >
                <PluseIcon />
              </button>
            </div>
          </ModalItemsContainer>
          <ModalItemsContainer>
            <Button className="resetButton" onClick={handleReset}>
              {t("filters_persons_count.reset_button")}
            </Button>
            <Button className="applyButton" onClick={handleApply}>
              {t("filters_persons_count.apply_button")}
            </Button>
          </ModalItemsContainer>
        </PersonModalContent>
      </Modal>
    </PersonModalContainer>
  );
};

export default React.memo(PersonCountModal);
