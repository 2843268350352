import styled from "styled-components";

export const SkeletonsComponentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: ${(props) => `${props.width}`};

  @media (max-width: 1470px) {
    width: 80%;
  }

  @media (max-width: 1100px) {
    width: 95%;
  }

  @media (max-width: 768px) {
    width: 70%;
    margin-top: 30px;
  }

  @media (max-width: 430px) {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  @media (max-width: 375px) {
    padding: 10px;
  }
`;

export const SelectionContainer = styled.div`
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  background-color: #ffffff;
  border: 1px solid #bdbdbd;
  display: flex;
  flex-direction: row;
  align-items: center;

  .ant-skeleton {
    margin-right: 20px;
  }

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;

    .skeletonImage {
      width: 90%;
      height: 200px;
      margin-bottom: 20px;
    }
  }
`;
