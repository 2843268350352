import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
    .personCountModal .ant-modal-close{
        display: none !important;
    }

    .dateModal {    
        .ant-modal-title{
            display: flex;
            justify-content: center;
        }
        .ant-modal-body{
            padding: 0;
        }

        @media (max-width: 768px) {
            width: 570px !important;
        }
        @media (max-width: 430px) {
            width: 330px !important;
        }
    }
    ::-webkit-scrollbar-thumb {
    background-color: #3486eb;
    border-radius: 3.5px;
    padding: 5px;
    width: 12px;
   }
   ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: white;
    padding: 5px;
  }

  .ant-select-dropdown{
    z-index: 20 !important;
  }
  
  .ant-picker-dropdown{ 
    z-index: 20 !important;
  }
`;

export default GlobalStyle;
