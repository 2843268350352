import styled from "styled-components";

export const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 60%;

  .backButton {
    border: none;
    background: none;
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    margin-right: 25%;
    margin-bottom: 30px;
    color: #3486eb;
    cursor: pointer;
    padding: 0px;

    svg {
      width: 20px;
      height: 20px;
      g path {
        fill: #3486eb;
      }
    }
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const TitleContainer = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  color: #494949;
  margin-bottom: 31px;
`;

export const ImagesContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  .ant-image {
    width: 388px;
    height: 210px;
  }

  @media (max-width: 1024px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  @media (max-width: 430px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  @media (max-width: 475px) {
    .ant-image {
      width: 300px;
      height: 210px;
    }
  }
`;

export const LeftImgContainer = styled.div`
  width: 388px;
  height: 204px;
  margin-right: 2px;

  @media (max-width: 430px) {
    margin-right: 0px;
    margin-bottom: 2px;
    width: 380px;
    height: 210px;
  }

  @media (max-width: 375px) {
    margin-right: 0px;
    margin-bottom: 2px;
    width: 300px;
    height: 200px;
  }

  @media (max-width: 320px) {
    margin-right: 0px;
    margin-bottom: 2px;
    width: 300px;
    height: 200px;
  }
`;

export const RightImgContainer = styled.div`
  display: ${(props) => `${props.display}`};
  flex-wrap: wrap;
  width: 215px;
  height: 205px;
  opacity: 0.6;
  div {
    width: 100px;
    height: 100px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    margin: 1px;
  }

  .moreImages {
    position: absolute;
    transform: translate(35%, 35%);
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 23px;
    color: #000000;
  }

  .hide {
    display: none;
  }
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  .infoText {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #7c8083;
  }

  svg {
    margin-right: 20px;
  }

  .iconContainer {
    display: flex;
    align-items: center;

    svg {
      width: 20px !important;
      height: 20px !important;
    }
  }
`;

export const Row1 = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid #d9d9d9;
  border-top: 1px solid #d9d9d9;
  padding: 12px 0px 12px 0px;
  margin-top: 17px;
  width: 55%;

  .maxPerson {
    margin-left: 25px;
  }

  @media (max-width: 430px) {
    width: 90%;
  }
`;

export const Row2 = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid #d9d9d9;
  padding: 12px 0px 12px 0px;
  width: 55%;

  .row2Container {
    display: flex;
    flex-direction: row;
  }

  .floor {
    margin: 0px 25px;
  }

  .elevator {
    color: #3486eb;
    margin-bottom: 2px;
  }

  .elevatorWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    width: 76px;
    justify-content: center;

    .elevator {
      color: #e0e0e0;
      margin-bottom: 2px;
    }

    svg {
      margin-right: 3px;
    }
  }

  .activeElevator {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    justify-content: center;
    width: auto;

    .activeElevatorText {
      color: #3486eb;
    }
    svg {
      margin-right: 10px;
    }

    svg path {
      fill: #3486eb;
    }
  }

  .line {
    position: absolute;
    width: 100%;
    border-top: 2px solid #bdbdbd;
    border-radius: 5px;
  }

  .areaWrapper {
    display: flex;
    flex-direction: row;
  }

  @media (max-width: 430px) {
    width: 90%;
  }

  @media (max-width: 375px) {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    .row2Container {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      margin-top: 20px;
    }

    .floor {
      margin: 0px 25px 0px 0px;
    }
  }
`;

export const Row3 = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 0px 12px 0px;
  border-bottom: 1px solid #d9d9d9;
  margin-bottom: 29px;
  width: 55%;
  height: 50px;

  svg g path {
    fill: #019734;
  }

  svg path {
    fill: #019734;
  }
  @media (max-width: 430px) {
    width: 90%;
  }

  @media (max-width: 375px) {
    border-bottom: none;
    margin-bottom: 0px;
  }
`;
export const HomeRolesContainer = styled.div`
  display: flex;
  flex-direction: column;

  .homeTitle {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #494949;
  }
  .checkInOut {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #494949;
    display: flex;
    flex-direction: row;
    margin: 10px 0px 14px;

    .checkIn {
      margin-right: 28px;
    }

    @media (max-width: 375px) {
      display: flex;
      flex-direction: column;

      .checkIn {
        margin-bottom: 15px;
      }
    }
  }
  .roles {
    height: 50px;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #3486eb;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    svg {
      margin-right: 5px;
      g path {
        fill: #3486eb;
      }
      path {
        fill: #3486eb;
      }
    }

    div {
      display: flex;
      align-items: center;
      margin-right: 15px;
    }
  }
  .prepayment {
    margin-top: 24px;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    color: #3e64ff;
  }
`;

export const ServicesTextContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 200px;

  .servicesText {
    margin-bottom: 5px;
    font-style: normal;
    font-size: 14px;
    line-height: 16px;
    color: #7c8083;
    margin-right: 10px;
  }
`;

export const AllServicesButton = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #494949;
  border: none;
  background: none;
  cursor: pointer;
`;
