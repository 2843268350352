import { ReactComponent as DailyRentIcon } from "../../assets/icon/DailyRentIcon.svg";
import { ReactComponent as CalendarIcon } from "../../assets/icon/CalendarIcon.svg";
import { ReactComponent as SaleIcon } from "../../assets/icon/SaleIcon.svg";

export const rout = (t) => {
  return [
    {
      name: t("header.daily_rent"),
      class: "dailyRent",
      pathName: "/",
      icon: <DailyRentIcon />,
    },
    {
      name: t("header.long-term_rent"),
      class: "dailyRent",
      pathName: "/long-term-rent",
      icon: <CalendarIcon />,
    },
    {
      name: t("header.sale"),
      class: "sale",
      pathName: "/sale",
      icon: <SaleIcon />,
    },
  ];
};
