import React, { useState } from "react";
import moment from "moment";
import { Button, Modal } from "antd";
import { DateRangePicker } from "react-date-range";
import { defaultStaticRanges } from "./dateHelper";
import { dateFormat } from "../../utils/constants";

import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { DateModalContainer, DaysCount } from "./styled";
import { addDays, endOfDay, startOfDay } from "date-fns";
import { useTranslation } from "react-i18next";

const DateModal = ({
  isDateModalOpen,
  setIsDateModalOpen,
  setIsPersonCountModalOpen,
  dataState,
  setDataState,
}) => {
  const { t } = useTranslation();
  const [checked, setChecked] = useState({
    tonight: false,
    tomorrow: false,
    nextWeekend: false,
    custom: false,
  });
  const dateRange = Math.ceil(
    (new Date(dataState[0].endDate) - new Date(dataState[0].startDate)) /
      (1000 * 3600 * 24)
  );

  const handleOnChange = (ranges) => {
    const { selection } = ranges;
    setDataState([selection]);
  };

  const onNextClick = () => {
    setIsDateModalOpen(false);
    setIsPersonCountModalOpen(true);
    if (
      moment(dataState[0].endDate).format("MM.DD.MM") ===
      moment(dataState[0].startDate).format("MM.DD.MM")
    ) {
      setDataState([
        {
          startDate: startOfDay(dataState[0].startDate),
          endDate: endOfDay(addDays(dataState[0].startDate, +1)),
          key: "selection",
        },
      ]);
    }
  };

  const handleCancel = () => {
    setIsDateModalOpen(false);
    setDataState([
      {
        startDate: startOfDay(new Date()),
        endDate: endOfDay(addDays(new Date(), +1)),
        key: "selection",
      },
    ]);
  };

  return (
    <Modal
      title={t("date_modal.select_check_in_out_date")}
      open={isDateModalOpen}
      onCancel={handleCancel}
      footer={false}
      className="dateModal"
      width={"890px"}
    >
      <DateModalContainer>
        <DateRangePicker
          onChange={handleOnChange}
          showSelectionPreview={true}
          moveRangeOnFirstSelection={false}
          months={2}
          ranges={dataState}
          direction="horizontal"
          staticRanges={defaultStaticRanges(dataState, checked, setChecked, t)}
          inputRanges={[]}
          showDateDisplay={false}
          minDate={new Date()}
        />
        <div className="bottomContainer">
          <div className="daysCountContainer">
            <div className="dateRang">
              {`${moment(dataState[0].startDate).format(dateFormat)} - ${moment(
                dataState[0].endDate
              ).format(dateFormat)}`}
            </div>
            <DaysCount>
              {dateRange > 0 ? (
                <>
                  <div>
                    {moment(
                      endOfDay(addDays(dataState[0].startDate, +1))
                    ).format("YYYY-MM-DD") ===
                    moment(dataState[0].endDate).format("YYYY-MM-DD")
                      ? 1
                      : dateRange}{" "}
                    {t("date_modal.night")}
                  </div>
                </>
              ) : (
                0
              )}
            </DaysCount>
          </div>
          <Button onClick={onNextClick}>{t("date_modal.next_button")}</Button>
        </div>
      </DateModalContainer>
    </Modal>
  );
};

export default DateModal;
